// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { TOTAL_RACE_INFORMATION, ADD_TOTAL_RACE_INFORMATION, GET_TOTAL_RACE_INFORMATION, EDIT_TOTAL_RACE_INFORMATION, DELETE_TOTAL_RACE_INFORMATION } from '../../common/constant'

export const actionRaceInfo = createAsyncThunk('appRaceInfo/actionRaceInfo', async (thunkAPI) => {
    return await post(TOTAL_RACE_INFORMATION, thunkAPI)
})

export const actionAddRaceInfo = createAsyncThunk('appRaceInfo/actionAddRaceInfo', async (params, thunkAPI) => {
    return await put(ADD_TOTAL_RACE_INFORMATION, params, thunkAPI)
})

export const actionGetRaceInfo = createAsyncThunk('appRaceInfo/actionGetRaceInfo', async (params, thunkAPI) => {
    return await post(GET_TOTAL_RACE_INFORMATION, params, thunkAPI)
})

export const actionEditRaceInfo = createAsyncThunk('appRaceInfo/actionEditRaceInfo', async (params, thunkAPI) => {
    return await post(EDIT_TOTAL_RACE_INFORMATION, params, thunkAPI)
})
export const actionDeleteRaceInfo = createAsyncThunk('appRaceInfo/actionDeleteRaceInfo', async (params, thunkAPI) => {
    return await post(DELETE_TOTAL_RACE_INFORMATION, params, thunkAPI)
})

export const appRaceInfoSlice = createSlice({
    name: 'appRaceInfo',
    initialState: {
        //list
        loading: false,
        totalRaceInfo: null,

        //add
        loadingAdd: false,
        addRaceInfo: null,

        //get
        loadingGet: false,
        getRaceInfo: null,

        //edit
        loadingEdit: false,
        editRaceInfo: null,

        //delete
        loadingDelete: false,
        deleteRaceInfo: null
    },
    reducers: {
        //reset or delete Add totalRaceInfo store data
        resetRaceInfoResponse: (state) => {
            state.totalRaceInfo = null
        },
        resetAddRaceInfoResponse: (state) => {
            state.addRaceInfo = null
        },
        resetGetRaceInfoResponse: (state) => {
            state.getRaceInfo = null
        },
        resetEditRaceInfoResponse: (state) => {
            state.editRaceInfo = null
        },
        resetDeleteRaceInfoResponse: (state) => {
            state.deleteRaceInfo = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionRaceInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(actionRaceInfo.fulfilled, (state, action) => {
                state.loading = false
                state.totalRaceInfo = action.payload
            })
            .addCase(actionRaceInfo.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddRaceInfo.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddRaceInfo.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addRaceInfo = action.payload
            })
            .addCase(actionAddRaceInfo.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetRaceInfo.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetRaceInfo.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getRaceInfo = action.payload
            })
            .addCase(actionGetRaceInfo.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditRaceInfo.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditRaceInfo.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editRaceInfo = action.payload
            })
            .addCase(actionEditRaceInfo.rejected, (state) => {
                state.loadingEdit = false
            })
            //delete
            .addCase(actionDeleteRaceInfo.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteRaceInfo.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteRaceInfo = action.payload
            })
            .addCase(actionDeleteRaceInfo.rejected, (state) => {
                state.loadingDelete = false
            })
    }
})
export const { resetRaceInfoResponse, resetAddRaceInfoResponse, resetGetRaceInfoResponse, resetEditRaceInfoResponse, resetDeleteRaceInfoResponse } = appRaceInfoSlice.actions

export default appRaceInfoSlice.reducer