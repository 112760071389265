// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../common/api'
import { SOCIAL_ICONS, ADD_STICKERS, EDIT_STICKERS, DELETE_STICKERS } from '../../common/constant'

export const actionSocialIcons = createAsyncThunk('appUser/actionSocialIcons', async (thunkAPI) => {
    return await get(SOCIAL_ICONS, thunkAPI)
})

export const actionStickerAdd = createAsyncThunk('appUser/actionStickerAdd', async (params, thunkAPI) => {
    return await post(ADD_STICKERS, params, thunkAPI)
})

export const actionStickerEdit = createAsyncThunk('appUser/actionStickerEdit', async (params, thunkAPI) => {
    return await post(EDIT_STICKERS, params, thunkAPI)
})

export const actionStickerDelete = createAsyncThunk('appUser/actionStickerDelete', async (params, thunkAPI) => {
    return await post(DELETE_STICKERS, params, thunkAPI)
})

export const appSocialIconsSlice = createSlice({
    name: 'appSocialIcons',
    initialState: {
        //list
        loading: false,
        socialIcons: null,

        //add
        loadingStickerAdd: false,
        addSticker: null,

        //edit
        loadingStickerEdit: false,
        editSticker: null,

        //delete
        loadingStickerDelete: false,
        deleteSticker: null

    },
    reducers: {
        //reset or delete Add User store data
        resetSocialIconsResponse: (state) => {
            state.socialIcons = null
        },
        resetAddStickerResponse: (state) => {
            state.addSticker = null
        },
        resetEditStickerResponse: (state) => {
            state.editSticker = null
        },
        resetDeleteStickerResponse: (state) => {
            state.deleteSticker = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionSocialIcons.pending, (state) => {
                state.loading = true
            })
            .addCase(actionSocialIcons.fulfilled, (state, action) => {
                state.loading = false
                state.socialIcons = action.payload
            })
            .addCase(actionSocialIcons.rejected, (state) => {
                state.loading = false
            })

            //add Sticker
            .addCase(actionStickerAdd.pending, (state) => {
                state.loadingStickerAdd = true
            })
            .addCase(actionStickerAdd.fulfilled, (state, action) => {
                state.loadingStickerAdd = false
                state.addSticker = action.payload
            })
            .addCase(actionStickerAdd.rejected, (state) => {
                state.loadingStickerAdd = false
            })

            //edit Sticker
            .addCase(actionStickerEdit.pending, (state) => {
                state.loadingStickerEdit = true
            })
            .addCase(actionStickerEdit.fulfilled, (state, action) => {
                state.loadingStickerEdit = false
                state.editSticker = action.payload
            })
            .addCase(actionStickerEdit.rejected, (state) => {
                state.loadingStickerEdit = false
            })

            //delete Sticker
            .addCase(actionStickerDelete.pending, (state) => {
                state.loadingStickerDelete = true
            })
            .addCase(actionStickerDelete.fulfilled, (state, action) => {
                state.loadingStickerDelete = false
                state.deleteSticker = action.payload
            })
            .addCase(actionStickerDelete.rejected, (state) => {
                state.loadingStickerDelete = false
            })

    }
})
export const { resetSocialIconsResponse, resetAddStickerResponse, resetEditStickerResponse, resetDeleteStickerResponse } = appSocialIconsSlice.actions

export default appSocialIconsSlice.reducer