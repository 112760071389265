// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { PRIZE_DMS_LIST, PRIZE_DMS_ADD, PRIZE_DMS_EDIT, PRIZE_DMS_DETAILS, PRIZE_DMS_DELETE, PRIZE_DMS_DROPDOWN_LIST } from '../../common/constant'

export const actionPrizeDMS = createAsyncThunk('appPrizeDMS/actionPrizeDMS', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_LIST, params, thunkAPI)
})

export const actionAddPrizeDMS = createAsyncThunk('appPrizeDMS/actionAddPrizeDMS', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_ADD, params, thunkAPI)
})

export const actionPrizeDMSDetails = createAsyncThunk('appPrizeDMS/actionPrizeDMSDetails', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_DETAILS, params, thunkAPI)
})

export const actionPrizeDMSEdit = createAsyncThunk('appPrizeDMS/actionPrizeDMSEdit', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_EDIT, params, thunkAPI)
})

export const actionPrizeDMSDelete = createAsyncThunk('appPrizeDMS/actionPrizeDMSDelete', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_DELETE, params, thunkAPI)
})

// Dropdown api
export const actionPrizeDMSList = createAsyncThunk('appPrizeDMS/actionPrizeDMSList', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_DROPDOWN_LIST, params, thunkAPI)
})

export const appStateSlice = createSlice({
    name: 'appPrizeDMS',
    initialState: {
        //list
        loading: false,
        prizeDMS: null,

        //add
        loadingAdd: false,
        addPrizeDMS: null,

        //get
        loadingGet: false,
        prizeDMSDetails: null,

        //edit
        loadingEdit: false,
        editPrizeDMS: null,

        //delete
        loadingDelete: false,
        deletePrizeDMS: null,

        //dropdown list
        loadingList: false,
        listPrizeDMS: null

    },
    reducers: {
        //reset or delete Add Insurance State store data
        resetPrizeDMSResponse: (state) => {
            state.prizeDMS = null
        },
        resetAddPrizeDMSResponse: (state) => {
            state.addPrizeDMS = null
        },
        resetPrizeDMSDetailsResponse: (state) => {
            state.prizeDMSDetails = null
        },
        resetEditPrizeDMSResponse: (state) => {
            state.editPrizeDMS = null
        },
        resetDeletePrizeDMSResponse: (state) => {
            state.editPrizeDMS = null
        },
        resetListPrizeDMSResponse: (state) => {
            state.listPrizeDMS = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionPrizeDMS.pending, (state) => {
                state.loading = true
            })
            .addCase(actionPrizeDMS.fulfilled, (state, action) => {
                state.loading = false
                state.prizeDMS = action.payload
            })
            .addCase(actionPrizeDMS.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddPrizeDMS.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddPrizeDMS.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addPrizeDMS = action.payload
            })
            .addCase(actionAddPrizeDMS.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionPrizeDMSDetails.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionPrizeDMSDetails.fulfilled, (state, action) => {
                state.loadingGet = false
                state.prizeDMSDetails = action.payload
            })
            .addCase(actionPrizeDMSDetails.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionPrizeDMSEdit.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionPrizeDMSEdit.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editPrizeDMS = action.payload
            })
            .addCase(actionPrizeDMSEdit.rejected, (state) => {
                state.loadingEdit = false
            })

            //delete
            .addCase(actionPrizeDMSDelete.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionPrizeDMSDelete.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deletePrizeDMS = action.payload
            })
            .addCase(actionPrizeDMSDelete.rejected, (state) => {
                state.loadingDelete = false
            })

            //delete
            .addCase(actionPrizeDMSList.pending, (state) => {
                state.loadingList = true
            })
            .addCase(actionPrizeDMSList.fulfilled, (state, action) => {
                state.loadingList = false
                state.listPrizeDMS = action.payload
            })
            .addCase(actionPrizeDMSList.rejected, (state) => {
                state.loadingList = false
            })

    }
})
export const { resetPrizeDMSResponse, resetAddPrizeDMSResponse, resetEditPrizeDMSResponse, resetPrizeDMSDetailsResponse, resetDeletePrizeDMSResponse, resetListPrizeDMSResponse } = appStateSlice.actions

export default appStateSlice.reducer