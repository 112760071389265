import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from "../../common/api"
import {
    EVENT_WINNING_RULES,
    ADD_EVENT_WINNING_RULE,
    EDIT_EVENT_WINNING_RULE
} from '../../common/constant'


export const actionEventWinningRules = createAsyncThunk('appEventWinningRule/actionEventWinningRules', async (thunkAPI) => {
    return await post(EVENT_WINNING_RULES, thunkAPI)
})

export const actionAddEventWinningRule = createAsyncThunk('appEventWinningRule/actionAddEventWinningRule', async (thunkAPI) => {
    return await post(ADD_EVENT_WINNING_RULE, thunkAPI)
})

export const actionEditEventWinningRule = createAsyncThunk('appEventWinningRule/actionEditEventWinningRule', async (thunkAPI) => {
    return await post(EDIT_EVENT_WINNING_RULE, thunkAPI)
})

export const appEventWinningRuleSlice = createSlice({
    name: 'appEventWinningRule',
    initialState: {
        //event winning rules
        loadingEventWinningRules: false,
        eventWinningRules: null,

        //add event winning rules
        loadingAddEventWinningRule: false,
        addEventWinningRule: null,

        //edit event winning rules
        loadingEditEventWinningRule: false,
        editEventWinningRule: null
    },
    reducers: {
        resetEventWinningRulesResponse: (state) => {
            state.eventWinningRules = null
        },
        resetAddEventWinningRuleResponse: (state) => {
            state.addEventWinningRule = null
        },
        resetEditEventWinningRuleResponse: (state) => {
            state.editEventWinningRule = null
        }
    },
    extraReducers: builder => {
        builder
            //event winning rules
            .addCase(actionEventWinningRules.pending, (state) => {
                state.loadingEventWinningRules = true
            })
            .addCase(actionEventWinningRules.fulfilled, (state, action) => {
                state.loadingEventWinningRules = false
                state.eventWinningRules = action.payload
            })
            .addCase(actionEventWinningRules.rejected, (state) => {
                state.loadingEventWinningRules = false
            })

            //add event winning rule
            .addCase(actionAddEventWinningRule.pending, (state) => {
                state.loadingAddEventWinningRule = true
            })
            .addCase(actionAddEventWinningRule.fulfilled, (state, action) => {
                state.loadingAddEventWinningRule = false
                state.addEventWinningRule = action.payload
            })
            .addCase(actionAddEventWinningRule.rejected, (state) => {
                state.loadingAddEventWinningRule = false
            })

            //edit event winning rule
            .addCase(actionEditEventWinningRule.pending, (state) => {
                state.loadingEditEventWinningRule = true
            })
            .addCase(actionEditEventWinningRule.fulfilled, (state, action) => {
                state.loadingEditEventWinningRule = false
                state.editEventWinningRule = action.payload
            })
            .addCase(actionEditEventWinningRule.rejected, (state) => {
                state.loadingEditEventWinningRule = false
            })
    }
})
export const {
    resetEventWinningRulesResponse,
    resetAddEventWinningRuleResponse,
    resetEditEventWinningRuleResponse
} = appEventWinningRuleSlice.actions

export default appEventWinningRuleSlice.reducer