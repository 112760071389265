// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, get } from '../../common/api'
import { ADD_NOTIFICATION, NOTIFICATION, EDIT_NOTIFICATION, GET_NOTIFICATION, SEND_NOTIFICATION, DELETE_NOTIFICATION } from '../../common/constant'

export const actionNotification = createAsyncThunk('appNotification/actionNotification', async (thunkAPI) => {
    return await get(NOTIFICATION, thunkAPI)
})

export const actionAddNotification = createAsyncThunk('appNotification/actionAddNotification', async (params, thunkAPI) => {
    return await post(ADD_NOTIFICATION, params, thunkAPI)
})

export const actionGetNotification = createAsyncThunk('appNotification/actionGetNotification', async (params, thunkAPI) => {
    return await post(GET_NOTIFICATION, params, thunkAPI)
})

export const actionEditNotification = createAsyncThunk('appNotification/actionEditNotification', async (params, thunkAPI) => {
    return await post(EDIT_NOTIFICATION, params, thunkAPI)
})

export const actionSendNotification = createAsyncThunk('appNotification/actionSendNotification', async (params, thunkAPI) => {
    return await post(SEND_NOTIFICATION, params, thunkAPI)
})

export const actionDeleteNotification = createAsyncThunk('appNotification/actionDeleteNotification', async (params, thunkAPI) => {
    return await post(DELETE_NOTIFICATION, params, thunkAPI)
})

export const appNotificationSlice = createSlice({
    name: 'appNotification',
    initialState: {
        //list
        loading: false,
        notification: null,

        //add
        loadingAdd: false,
        addNotification: null,

        //get
        loadingGet: false,
        getNotification: null,

        //edit
        loadingEdit: false,
        editNotification: null,

        //send
        loadingSend: false,
        sendNotification: null,

        //delete
        loadingDelete: false,
        deleteNotification: null
    },
    reducers: {
        //reset or delete Add Country store data
        resetNotificationResponse: (state) => {
            state.notification = null
        },
        resetAddNotificationResponse: (state) => {
            state.addNotification = null
        },
        resetGetNotificationResponse: (state) => {
            state.getNotification = null
        },
        resetEditNotificationResponse: (state) => {
            state.editNotification = null
        },
        resetSendNotificationResponse: (state) => {
            state.sendNotification = null
        },
        resetDeleteNotificationResponse: (state) => {
            state.deleteNotification = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionNotification.pending, (state) => {
                state.loading = true
            })
            .addCase(actionNotification.fulfilled, (state, action) => {
                state.loading = false
                state.notification = action.payload
            })
            .addCase(actionNotification.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddNotification.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddNotification.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addNotification = action.payload
            })
            .addCase(actionAddNotification.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetNotification.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetNotification.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getNotification = action.payload
            })
            .addCase(actionGetNotification.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditNotification.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditNotification.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editNotification = action.payload
            })
            .addCase(actionEditNotification.rejected, (state) => {
                state.loadingEdit = false
            })

            //send
            .addCase(actionSendNotification.pending, (state) => {
                state.loadingSend = true
            })
            .addCase(actionSendNotification.fulfilled, (state, action) => {
                state.loadingSend = false
                state.sendNotification = action.payload
            })
            .addCase(actionSendNotification.rejected, (state) => {
                state.loadingSend = false
            })


            //delete
            .addCase(actionDeleteNotification.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteNotification.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteNotification = action.payload
            })
            .addCase(actionDeleteNotification.rejected, (state) => {
                state.loadingDelete = false
            })
    }
})
export const { resetNotificationResponse, resetAddNotificationResponse, resetGetNotificationResponse, resetEditNotificationResponse, resetSendNotificationResponse, resetDeleteNotificationResponse } = appNotificationSlice.actions

export default appNotificationSlice.reducer