import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { multipart, post } from "../../common/api"
import {
    SEARCH_USER_BY_MOBILE,
    EVENT_PARTICIPANT_ADD,
    EVENT_PARTICIPANTS,
    GENERATE_TOKEN,
    EVENT_PARTICIPANT_GENERATE_ROUND,
    EVENT_PARTICIPANT_EDIT,
    EVENT_PARTICIPANT_DETAILS,
    EVENT_PARTICIPANT_DELETE,
    USER_SEARCH,
    EVENT_PARTICIPANT_TOKEN_UPDATE,
    UPLOAD_CERTIFICATE
} from '../../common/constant'

export const actionSearchUserByMobile = createAsyncThunk('appEventParticipant/actionSearchUserByMobile', async (thunkAPI) => {
    return await post(SEARCH_USER_BY_MOBILE, thunkAPI)
})

export const actionEventParticipantAdd = createAsyncThunk('appEventParticipant/actionEventParticipantAdd', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_ADD, thunkAPI)
})

export const actionEventParticipantEdit = createAsyncThunk('appEventParticipant/actionEventParticipantEdit', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_EDIT, thunkAPI)
})

export const actionEventParticipantDetails = createAsyncThunk('appEventParticipant/actionEventParticipantDetails', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_DETAILS, thunkAPI)
})

export const actionEventParticipants = createAsyncThunk('appEventParticipant/actionEventParticipants', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANTS, thunkAPI)
})

export const actionGenerateToken = createAsyncThunk('appEventParticipant/actionGenerateToken', async (thunkAPI) => {
    return await post(GENERATE_TOKEN, thunkAPI)
})

export const actionEventParticipantGenerateRound = createAsyncThunk('appEventParticipant/actionEventParticipantGenerateRound', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_GENERATE_ROUND, thunkAPI)
})

export const actionEventDisqualifyParticipant = createAsyncThunk('appEventParticipant/actionEventDisqualifyParticipant', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANTS, thunkAPI)
})

export const actionEventParticipantDelete = createAsyncThunk('appEventParticipant/actionEventParticipantDelete', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_DELETE, thunkAPI)
})

export const actionUserSearch = createAsyncThunk('appEventParticipant/actionUserSearch', async (thunkAPI) => {
    return await post(USER_SEARCH, thunkAPI)
})

export const actionEventParticipantTokenUpdate = createAsyncThunk('appEventParticipant/actionEventParticipantTokenUpdate', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_TOKEN_UPDATE, thunkAPI)
})

export const actionUploadCertificate = createAsyncThunk('appEventParticipant/actionUploadCertificate', async (thunkAPI) => {
    return await multipart(UPLOAD_CERTIFICATE, thunkAPI)
})

export const appEventParticipantSlice = createSlice({
    name: 'appEventParticipant',
    initialState: {
        //search user by mobile
        loadingSearchUserByMobile: false,
        searchUserByMobile: null,

        //Event participant add
        loadingEventParticipantAdd: false,
        eventParticipantAdd: null,

        //Event participant Edit
        loadingEventParticipantEdit: false,
        eventParticipantEdit: null,

        //Event participant Details
        loadingEventParticipantDetails: false,
        eventParticipantDetails: null,

        //Event participants
        loadingEventParticipants: false,
        eventParticipants: null,

        //generate token
        loadingGenerateToken: false,
        generateToken: null,

        //EventParticipantGenerateRound
        loadingEventParticipantGenerateRound: false,
        eventParticipantGenerateRound: null,

        //event disqualify participant
        loadingDisqualifyParticipant: false,
        disqualifyParticipant: null,

        // Event Participant Delete
        loadingEventParticipantDelete: false,
        eventParticipantDelete: null,

        // user search
        loadingUserSearch: false,
        userSearch: null,

        // event participant token update
        loadingEventParticipantTokenUpdate: false,
        eventParticipantTokenUpdate: null,

        //upload certificate
        loadingUploadCertificate: false,
        uploadCertificate: null
    },
    reducers: {
        resetSearchUserByMobileResponse: (state) => {
            state.searchUserByMobile = null
        },
        resetAddEventParticipantResponse: (state) => {
            state.eventParticipantAdd = null
        },
        resetEditEventParticipantResponse: (state) => {
            state.eventParticipantEdit = null
        },
        resetDetailsEventParticipantResponse: (state) => {
            state.eventParticipantDetails = null
        },
        resetEventParticipantsResponse: (state) => {
            state.eventParticipants = null
        },
        resetGenerateToken: (state) => {
            state.generateToken = null
        },
        resetEventDisqualifyParticipant: (state) => {
            state.disqualifyParticipant = null
        },
        resetEventParticipantDelete: (state) => {
            state.eventParticipantDelete = null
        },
        resetUserSearch: (state) => {
            state.userSearch = null
        },
        resetEventParticipantTokenUpdate: (state) => {
            state.eventParticipantTokenUpdate = null
        },
        resetUploadCertificate: (state) => {
            state.uploadCertificate = null
        }
    },
    extraReducers: builder => {
        builder
            //search user by mobile
            .addCase(actionSearchUserByMobile.pending, (state) => {
                state.loadingSearchUserByMobile = true
            })
            .addCase(actionSearchUserByMobile.fulfilled, (state, action) => {
                state.loadingSearchUserByMobile = false
                state.searchUserByMobile = action.payload
            })
            .addCase(actionSearchUserByMobile.rejected, (state) => {
                state.loadingSearchUserByMobile = false
            })

            //add event participants
            .addCase(actionEventParticipantAdd.pending, (state) => {
                state.loadingEventParticipantAdd = true
            })
            .addCase(actionEventParticipantAdd.fulfilled, (state, action) => {
                state.loadingEventParticipantAdd = false
                state.eventParticipantAdd = action.payload
            })
            .addCase(actionEventParticipantAdd.rejected, (state) => {
                state.loadingEventParticipantAdd = false
            })

            //Edit event participants
            .addCase(actionEventParticipantEdit.pending, (state) => {
                state.loadingEventParticipantEdit = true
            })
            .addCase(actionEventParticipantEdit.fulfilled, (state, action) => {
                state.loadingEventParticipantEdit = false
                state.eventParticipantEdit = action.payload
            })
            .addCase(actionEventParticipantEdit.rejected, (state) => {
                state.loadingEventParticipantEdit = false
            })

            //Details event participants
            .addCase(actionEventParticipantDetails.pending, (state) => {
                state.loadingEventParticipantDetails = true
            })
            .addCase(actionEventParticipantDetails.fulfilled, (state, action) => {
                state.loadingEventParticipantDetails = false
                state.eventParticipantDetails = action.payload
            })
            .addCase(actionEventParticipantDetails.rejected, (state) => {
                state.loadingEventParticipantDetails = false
            })

            //event participants
            .addCase(actionEventParticipants.pending, (state) => {
                state.loadingEventParticipants = true
            })
            .addCase(actionEventParticipants.fulfilled, (state, action) => {
                state.loadingEventParticipants = false
                state.eventParticipants = action.payload
            })
            .addCase(actionEventParticipants.rejected, (state) => {
                state.loadingEventParticipants = false
            })

            //generate token
            .addCase(actionGenerateToken.pending, (state) => {
                state.loadingGenerateToken = true
            })
            .addCase(actionGenerateToken.fulfilled, (state, action) => {
                state.loadingGenerateToken = false
                state.generateToken = action.payload
            })
            .addCase(actionGenerateToken.rejected, (state) => {
                state.loadingGenerateToken = false
            })

            //event disqualifyParticipant
            .addCase(actionEventDisqualifyParticipant.pending, (state) => {
                state.loadingDisqualifyParticipant = true
            })
            .addCase(actionEventDisqualifyParticipant.fulfilled, (state, action) => {
                state.loadingDisqualifyParticipant = false
                state.disqualifyParticipant = action.payload
            })
            .addCase(actionEventDisqualifyParticipant.rejected, (state) => {
                state.loadingDisqualifyParticipant = false
            })

            //user search
            .addCase(actionUserSearch.pending, (state) => {
                state.loadingUserSearch = true
            })
            .addCase(actionUserSearch.fulfilled, (state, action) => {
                state.loadingUserSearch = false
                state.userSearch = action.payload
            })
            .addCase(actionUserSearch.rejected, (state) => {
                state.loadingUserSearch = false
            })

            // event participant token update
            .addCase(actionEventParticipantTokenUpdate.pending, (state) => {
                state.loadingEventParticipantTokenUpdate = true
            })
            .addCase(actionEventParticipantTokenUpdate.fulfilled, (state, action) => {
                state.loadingEventParticipantTokenUpdate = false
                state.eventParticipantTokenUpdate = action.payload
            })
            .addCase(actionEventParticipantTokenUpdate.rejected, (state) => {
                state.loadingEventParticipantTokenUpdate = false
            })

            // upload certificate
            .addCase(actionUploadCertificate.pending, (state) => {
                state.loadingUploadCertificate = true
            })
            .addCase(actionUploadCertificate.fulfilled, (state, action) => {
                state.loadingUploadCertificate = false
                state.uploadCertificate = action.payload
            })
            .addCase(actionUploadCertificate.rejected, (state) => {
                state.loadingUploadCertificate = false
            })

            //event participant delete
            .addCase(actionEventParticipantDelete.pending, (state) => {
                state.loadingEventParticipantDelete = true
            })
            .addCase(actionEventParticipantDelete.fulfilled, (state, action) => {
                state.loadingEventParticipantDelete = false
                state.eventParticipantDelete = action.payload
            })
            .addCase(actionEventParticipantDelete.rejected, (state) => {
                state.loadingEventParticipantDelete = false
            })

            //eventParticipantGenerateRound
            .addCase(actionEventParticipantGenerateRound.pending, (state) => {
                state.loadingEventParticipantGenerateRound = true
            })
            .addCase(actionEventParticipantGenerateRound.fulfilled, (state, action) => {
                state.loadingEventParticipantGenerateRound = false
                state.eventParticipantGenerateRound = action.payload
            })
            .addCase(actionEventParticipantGenerateRound.rejected, (state) => {
                state.loadingEventParticipantGenerateRound = false
            })
    }
})
export const {
    resetSearchUserByMobileResponse,
    resetAddEventParticipantResponse,
    resetEditEventParticipantResponse,
    resetDetailsEventParticipantResponse,
    resetEventParticipantsResponse,
    resetGenerateToken,
    resetEventDisqualifyParticipant,
    resetEventParticipantDelete,
    resetUserSearch,
    resetEventParticipantTokenUpdate,
    resetUploadCertificate
} = appEventParticipantSlice.actions

export default appEventParticipantSlice.reducer