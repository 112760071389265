// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { EVENT_RULES, EVENT_RULES_DETAILS } from '../../common/constant'
import { post } from '../../common/api'

//update Rules
export const actionUpdateEventRules = createAsyncThunk('appEventRules/actionUpdateEventRules', async (params, thunkAPI) => {
    return await post(EVENT_RULES, params, thunkAPI)
})

//Details rules
export const actionDetailsEventRules = createAsyncThunk('appEventRules/actionDetailsEventRules', async (params, thunkAPI) => {
    return await post(EVENT_RULES_DETAILS, params, thunkAPI)
})

export const appEventRulesSlice = createSlice({
    name: 'appEventRules',
    initialState: {
        //update Event Rules
        loadingUpdateEventRules: false,
        updateEventRules: null,
         
        //update Event Rules
        loadingDetailsEventRules: false,
        detailsEventRules: null
    },
    reducers: {
        resetUpdateEventRules: (state) => {
            state.updateEventRules = null
        },
        resetDetailsEventRules: (state) => {
            state.detailsEventRules = null
        }
    },
    extraReducers: builder => {
        builder
            //Update Event Rules
            .addCase(actionUpdateEventRules.pending, (state) => {
                state.loadingUpdateEventRules = true
            })
            .addCase(actionUpdateEventRules.fulfilled, (state, action) => {
                state.loadingUpdateEventRules = false
                state.updateEventRules = action.payload
            })
            .addCase(actionUpdateEventRules.rejected, (state) => {
                state.loadingUpdateEventRules = false
            })

            //Details Event Rules
            .addCase(actionDetailsEventRules.pending, (state) => {
                state.loadingDetailsEventRules = true
            })
            .addCase(actionDetailsEventRules.fulfilled, (state, action) => {
                state.loadingDetailsEventRules = false
                state.detailsEventRules = action.payload
            })
            .addCase(actionDetailsEventRules.rejected, (state) => {
                state.loadingDetailsEventRules = false
            })
    }
})
export const { resetUpdateEventRules, resetDetailsEventRules } = appEventRulesSlice.actions

export default appEventRulesSlice.reducer