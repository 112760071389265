// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, get } from '../../common/api'
import {
    EVENTS, EVENT_ADD, EVENT_DETAILS, EVENT_EDIT, EVENT_DELETE, GAME_TYPE, ASSIGN_ADVERTISEMENT_TO_EVENT,
    ATTACHED_ADS, EVENT_UPDATE_STATUS, EVENTS_LIST, EVENTS_APPROVAL, EVENTS_REMOVE, EVENTS_LIVE_STATUS, EVENT_ROUND_LIST,
    ADD_DESCRIPTION
} from '../../common/constant'
// import state from '../state'

export const actionEvents = createAsyncThunk('appEvent/actionEvents', async (thunkAPI) => {
    return await post(EVENTS, thunkAPI)
})

//dropdown API for Events
export const actionEventList = createAsyncThunk('appEvent/actionEventList', async (thunkAPI) => {
    return await get(EVENTS_LIST, thunkAPI)
})

export const actionEventAdd = createAsyncThunk('appEvent/actionEventAdd', async (params, thunkAPI) => {
    return await post(EVENT_ADD, params, thunkAPI)
})

export const actionEventDetails = createAsyncThunk('appEvent/actionEventDetails', async (params, thunkAPI) => {
    return await post(EVENT_DETAILS, params, thunkAPI)
})

export const actionEventEdit = createAsyncThunk('appEvent/actionEventEdit', async (params, thunkAPI) => {
    return await post(EVENT_EDIT, params, thunkAPI)
})
export const actionDeleteEvent = createAsyncThunk('appEvent/actionDeleteEvent', async (params, thunkAPI) => {
    return await post(EVENT_DELETE, params, thunkAPI)
})
export const actionEventUpdateStatus = createAsyncThunk('appEvent/actionEventUpdateStatus', async (thunkAPI) => {
    return await post(EVENT_UPDATE_STATUS, thunkAPI)
})
export const actionGameType = createAsyncThunk('appEvent/actionGameType', async (params, thunkAPI) => {
    return await post(GAME_TYPE, params, thunkAPI)
})

export const actionAssignAdvertisementToEvent = createAsyncThunk('appEvent/actionAssignAdvertisementToEvent', async (thunkAPI) => {
    return await post(ASSIGN_ADVERTISEMENT_TO_EVENT, thunkAPI)
})

export const actionAttachedAds = createAsyncThunk('appEvent/actionAttachedAds', async (thunkAPI) => {
    return await post(ATTACHED_ADS, thunkAPI)
})

export const actionEventApproval = createAsyncThunk('appEvent/actionEventApproval', async (thunkAPI) => {
    return await post(EVENTS_APPROVAL, thunkAPI)
})

export const actionEventRemove = createAsyncThunk('appEvent/actionEventRemove', async (thunkAPI) => {
    return await post(EVENTS_REMOVE, thunkAPI)
})
export const actionEventLiveStatus = createAsyncThunk('appEvent/actionEventLiveStatus', async (thunkAPI) => {
    return await post(EVENTS_LIVE_STATUS, thunkAPI)
})
export const actionEventRoundList = createAsyncThunk('appEvent/actionEventRoundList', async (thunkAPI) => {
    return await post(EVENT_ROUND_LIST, thunkAPI)
})
export const actionAddDescription = createAsyncThunk('appEvent/actionAddDescription', async (thunkAPI) => {
    return await post(ADD_DESCRIPTION, thunkAPI)
})

export const appEventSlice = createSlice({
    name: 'appEvent',
    initialState: {
        //list
        loadingEvents: false,
        events: null,

        //dropdown list
        loadingEventList: false,
        eventList: null,

        //add event
        loadingEventAdd: false,
        eventAdd: null,

        //event details
        loadingEventDetails: false,
        eventDetails: null,

        // event edit
        loadingEventEdit: false,
        eventEdit: null,

        //delete
        loadingDelete: false,
        deleteEvent: null,

        // EventUpdateStatus
        loadingEventUpdateStatus: false,
        eventUpdateStatus: null,

        //game type list
        loadingGameType: false,
        gameType: null,

        //assign advertisement to event
        loadingAssignAdvertisementToEvent: false,
        assignAdvertisementToEvent: false,

        //attached ads
        loadingAttachedAds: false,
        attachedAds: null,

        //event approval
        loadingEventApproval: false,
        eventApproval: null,

        //event remove
        loadingEventRemove: false,
        eventRemove: null,

        //event Live status
        loadingEventLive: false,
        eventLiveStatus: null,

        //event Round List Dropdown
        loadingEventRoundList: false,
        eventRoundList: null,

        //event Description add
        loadingAddDescription: false,
        addDescription: null


    },
    reducers: {
        //reset or delete Add store data
        resetEventsResponse: (state) => {
            state.events = null
        },
        //dropdown
        resetEventListResponse: (state) => {
            state.eventList = null
        },
        resetEventAddResponse: (state) => {
            state.eventAdd = null
        },
        resetEventDetailsResponse: (state) => {
            state.eventDetails = null
        },
        resetEventEdit: (state) => {
            state.eventEdit = null
        },
        resetDeleteEventResponse: (state) => {
            state.deleteEvent = null
        },
        resetEventUpdateStatus: (state) => {
            state.eventUpdateStatus = null
        },
        resetGameTypeResponse: (state) => {
            state.gameType = null
        },
        resetAssignAdvertisementToEvent: (state) => {
            state.assignAdvertisementToEvent = null
        },
        resetAttachedAds: (state) => {
            state.attachedAds = null
        },
        resetEventApproval: (state) => {
            state.eventApproval = null
        },
        resetEventRemove: (state) => {
            state.eventRemove = null
        },
        resetEventLiveStatus: (state) => {
            state.eventLiveStatus = null
        },
        resetEventRoundList: (state) => {
            state.eventRoundList = null
        },
        resetAddDescriptionResponse: (state) => {
            state.addDescription = null
        }
    },
    extraReducers: builder => {
        builder
            //events
            .addCase(actionEvents.pending, (state) => {
                state.loadingEvents = true
            })
            .addCase(actionEvents.fulfilled, (state, action) => {
                state.loadingEvents = false
                state.events = action.payload
            })
            .addCase(actionEvents.rejected, (state) => {
                state.loadingEvents = false
            })

            // Dropdown API
            .addCase(actionEventList.pending, (state) => {
                state.loadingEventList = true
            })
            .addCase(actionEventList.fulfilled, (state, action) => {
                state.loadingEventList = false
                state.eventList = action.payload
            })
            .addCase(actionEventList.rejected, (state) => {
                state.loadingEventList = false
            })

            //event add
            .addCase(actionEventAdd.pending, (state) => {
                state.loadingEventAdd = true
            })
            .addCase(actionEventAdd.fulfilled, (state, action) => {
                state.loadingEventAdd = false
                state.eventAdd = action.payload
            })
            .addCase(actionEventAdd.rejected, (state) => {
                state.loadingEventAdd = false
            })

            //event details
            .addCase(actionEventDetails.pending, (state) => {
                state.loadingEventDetails = true
            })
            .addCase(actionEventDetails.fulfilled, (state, action) => {
                state.loadingEventDetails = false
                state.eventDetails = action.payload
            })
            .addCase(actionEventDetails.rejected, (state) => {
                state.loadingEventDetails = false
            })

            // event edit
            .addCase(actionEventEdit.pending, (state) => {
                state.loadingEventEdit = true
            })
            .addCase(actionEventEdit.fulfilled, (state, action) => {
                state.loadingEventEdit = false
                state.eventEdit = action.payload
            })
            .addCase(actionEventEdit.rejected, (state) => {
                state.loadingEventEdit = false
            })

            //delete
            .addCase(actionDeleteEvent.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteEvent.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteEvent = action.payload
            })
            .addCase(actionDeleteEvent.rejected, (state) => {
                state.loadingDelete = false
            })

            //event update status
            .addCase(actionEventUpdateStatus.pending, (state) => {
                state.loadingEventUpdateStatus = true
            })
            .addCase(actionEventUpdateStatus.fulfilled, (state, action) => {
                state.loadingEventUpdateStatus = false
                state.eventUpdateStatus = action.payload
            })
            .addCase(actionEventUpdateStatus.rejected, (state) => {
                state.loadingEventUpdateStatus = false
            })

            // Game Type list
            .addCase(actionGameType.pending, (state) => {
                state.loadingGameType = true
            })
            .addCase(actionGameType.fulfilled, (state, action) => {
                state.loadingGameType = false
                state.gameType = action.payload
            })
            .addCase(actionGameType.rejected, (state) => {
                state.loadingGameType = false
            })

            // assign advertisement to token
            .addCase(actionAssignAdvertisementToEvent.pending, (state) => {
                state.loadingAssignAdvertisementToEvent = true
            })
            .addCase(actionAssignAdvertisementToEvent.fulfilled, (state, action) => {
                state.loadingAssignAdvertisementToEvent = false
                state.assignAdvertisementToEvent = action.payload
            })
            .addCase(actionAssignAdvertisementToEvent.rejected, (state) => {
                state.loadingAssignAdvertisementToEvent = false
            })

            // attached ads
            .addCase(actionAttachedAds.pending, (state) => {
                state.loadingAttachedAds = true
            })
            .addCase(actionAttachedAds.fulfilled, (state, action) => {
                state.loadingAttachedAds = false
                state.attachedAds = action.payload
            })
            .addCase(actionAttachedAds.rejected, (state) => {
                state.loadingAttachedAds = false
            })

            // event approval
            .addCase(actionEventApproval.pending, (state) => {
                state.loadingEventApproval = true
            })
            .addCase(actionEventApproval.fulfilled, (state, action) => {
                state.loadingEventApproval = false
                state.eventApproval = action.payload
            })
            .addCase(actionEventApproval.rejected, (state) => {
                state.loadingEventApproval = false
            })

            // event Remove
            .addCase(actionEventRemove.pending, (state) => {
                state.loadingEventRemove = true
            })
            .addCase(actionEventRemove.fulfilled, (state, action) => {
                state.loadingEventRemove = false
                state.eventRemove = action.payload
            })
            .addCase(actionEventRemove.rejected, (state) => {
                state.loadingEventRemove = false
            })
            //Event Live Status
            .addCase(actionEventLiveStatus.pending, (state) => {
                state.loadingEventLive = true
            })
            .addCase(actionEventLiveStatus.fulfilled, (state, action) => {
                state.loadingEventLive = false
                state.eventLiveStatus = action.payload
            })
            .addCase(actionEventLiveStatus.rejected, (state) => {
                state.loadingEventLive = false
            })
            //event round dropdown list
            .addCase(actionEventRoundList.pending, (state) => {
                state.loadingEventRoundList = true
            })
            .addCase(actionEventRoundList.fulfilled, (state, action) => {
                state.loadingEventRoundList = false
                state.eventRoundList = action.payload
            })
            .addCase(actionEventRoundList.rejected, (state) => {
                state.loadingEventRoundList = false
            })
            //event add description
            .addCase(actionAddDescription.pending, (state) => {
                state.loadingAddDescription = true
            })
            .addCase(actionAddDescription.fulfilled, (state, action) => {
                state.loadingAddDescription = false
                state.addDescription = action.payload
            })
            .addCase(actionAddDescription.rejected, (state) => {
                state.loadingAddDescription = false
            })
    }
})
export const { resetEventAddResponse, resetEventListResponse,
    resetEventsResponse,
    resetEventDetailsResponse, resetEventEdit, resetDeleteEventResponse, resetEventUpdateStatus,
    resetGameTypeResponse, resetAssignAdvertisementToEvent, resetEventApproval,
    resetAttachedAds, resetEventRemove, resetEventLiveStatus, resetEventRoundList, resetAddDescriptionResponse } = appEventSlice.actions

export default appEventSlice.reducer