import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FINAL_REPORT_RESULT_1, FINAL_REPORT_RESULT_2, FINAL_REPORT_RESULT_3, FINAL_REPORT_RESULT_4, FINAL_REPORT_RESULT_5 } from '../../common/constant'
import { post } from '../../common/api'


//List
export const actionFinalReport1 = createAsyncThunk('appFinalReports/actionFinalReportList', async (params, thunkAPI) => {
    return await post(FINAL_REPORT_RESULT_1, params, thunkAPI)
})

export const actionFinalReport2 = createAsyncThunk('appFinalReports/actionFinalReport2List', async (params, thunkAPI) => {
    return await post(FINAL_REPORT_RESULT_2, params, thunkAPI)
})

export const actionFinalReport3 = createAsyncThunk('appFinalReports/actionFinalReport3List', async (params, thunkAPI) => {
    return await post(FINAL_REPORT_RESULT_3, params, thunkAPI)
})

export const actionFinalReport4 = createAsyncThunk('appFinalReports/actionFinalReport4List', async (params, thunkAPI) => {
    return await post(FINAL_REPORT_RESULT_4, params, thunkAPI)
})

export const actionFinalReport5 = createAsyncThunk('appFinalReports/actionFinalReport5List', async (params, thunkAPI) => {
    return await post(FINAL_REPORT_RESULT_5, params, thunkAPI)
})

export const finalReportsSlice = createSlice({
    name: 'appFinalReports',
    initialState: {
        //list
        loadingFinalReport1: false,
        finalReport1: null,
        //list report2
        loadingFinalReport2: false,
        finalReport2: null,
        //list report3        
        loadingFinalReport3: false,
        finalReport3: null,
        //list report4        
        loadingFinalReport4: false,
        finalReport4: null,
        //list report5        
        loadingFinalReport5: false,
        finalReport5: null
    },
    reducers: {
        // List store
        resetFinalReport1Response: (state) => {
            state.finalReport1 = null
        },
        resetFinalReport2Response: (state) => {
            state.finalReport2 = null
        },
        resetFinalReport3Response: (state) => {
            state.finalReport3 = null
        },
        resetFinalReport4Response: (state) => {
            state.finalReport4 = null
        },
        resetFinalReport5Response: (state) => {
            state.finalReport5 = null
        }
    },
    extraReducers: builder => {
        builder
            //List Report1 
            .addCase(actionFinalReport1.pending, (state) => {
                state.loadingFinalReport1 = true
            })
            .addCase(actionFinalReport1.fulfilled, (state, action) => {
                state.loadingFinalReport1 = false
                state.finalReport1 = action.payload
            })
            .addCase(actionFinalReport1.rejected, (state) => {
                state.loadingFinalReport1 = false
            })

            //List Report2
            .addCase(actionFinalReport2.pending, (state) => {
                state.loadingFinalReport2 = true
            })
            .addCase(actionFinalReport2.fulfilled, (state, action) => {
                state.loadingFinalReport2 = false
                state.finalReport2 = action.payload
            })
            .addCase(actionFinalReport2.rejected, (state) => {
                state.loadingFinalReport2 = false
            })

            //List Report3
            .addCase(actionFinalReport3.pending, (state) => {
                state.loadingFinalReport3 = true
            })
            .addCase(actionFinalReport3.fulfilled, (state, action) => {
                state.loadingFinalReport3 = false
                state.finalReport3 = action.payload
            })
            .addCase(actionFinalReport3.rejected, (state) => {
                state.loadingFinalReport3 = false
            })

            //List Report4
            .addCase(actionFinalReport4.pending, (state) => {
                state.loadingFinalReport4 = true
            })
            .addCase(actionFinalReport4.fulfilled, (state, action) => {
                state.loadingFinalReport4 = false
                state.finalReport4 = action.payload
            })
            .addCase(actionFinalReport4.rejected, (state) => {
                state.loadingFinalReport4 = false
            })

            //List Report5
            .addCase(actionFinalReport5.pending, (state) => {
                state.loadingFinalReport5 = true
            })
            .addCase(actionFinalReport5.fulfilled, (state, action) => {
                state.loadingFinalReport5 = false
                state.finalReport5 = action.payload
            })
            .addCase(actionFinalReport5.rejected, (state) => {
                state.loadingFinalReport5 = false
            })
    }
})
export const { resetFinalReport1Response, resetFinalReport2Response, resetFinalReport3Response, resetFinalReport4Response, resetFinalReport5Response } = finalReportsSlice.actions

export default finalReportsSlice.reducer