// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get } from '../../common/api'
import {PREMIUM_ADVERTISEMENT_LIST } from '../../common/constant'

export const actionPremiumAdvertisement = createAsyncThunk('appPremiumAdvertisement/actionPremiumAdvertisement', async (params, thunkAPI) => {
    return await get(PREMIUM_ADVERTISEMENT_LIST, params, thunkAPI)
})

export const appPremiumAdvertisementSLice = createSlice({
    name: 'appPremiumAdvertisement',
    initialState: {
        //list
        loadingPremiumAdvertisement: false,
        premiumAdvertisement: null
    },
    reducers: {
        //reset or delete Add Insurance State store data
        resetPremiumAdvertisementResponse: (state) => {
            state.premiumAdvertisement = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionPremiumAdvertisement.pending, (state) => {
                state.loadingPremiumAdvertisement = true
            })
            .addCase(actionPremiumAdvertisement.fulfilled, (state, action) => {
                state.loadingPremiumAdvertisement = false
                state.premiumAdvertisement = action.payload
            })
            .addCase(actionPremiumAdvertisement.rejected, (state) => {
                state.loadingPremiumAdvertisement = false
            })
    }
})
export const { resetPremiumAdvertisementResponse } = appPremiumAdvertisementSLice.actions

export default appPremiumAdvertisementSLice.reducer