// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put, get } from '../../common/api'
import { ADD_LANGUAGE, LANGUAGE, EDIT_LANGUAGE, GET_LANGUAGE } from '../../common/constant'

export const actionLanguage = createAsyncThunk('appLanguage/actionLanguage', async (thunkAPI) => {
    return await get(LANGUAGE, thunkAPI)
})

export const actionAddLanguage = createAsyncThunk('appLanguage/actionAddLanguage', async (params, thunkAPI) => {
    return await put(ADD_LANGUAGE, params, thunkAPI)
})

export const actionGetLanguage = createAsyncThunk('appLanguage/actionGetLanguage', async (params, thunkAPI) => {
    return await post(GET_LANGUAGE, params, thunkAPI)
})

export const actionEditLanguage = createAsyncThunk('appLanguage/actionEditLanguage', async (params, thunkAPI) => {
    return await post(EDIT_LANGUAGE, params, thunkAPI)
})

export const appLanguageSlice = createSlice({
    name: 'appLanguage',
    initialState: {
        //list
        loading: false,
        language: null,

        //add
        loadingAdd: false,
        addLanguage: null,

        //get
        loadingGet: false,
        getLanguage: null,

        //edit
        loadingEdit: false,
        editLanguage: null

    },
    reducers: {
        //reset or delete Add Insurance Language store data
        resetLanguageResponse: (state) => {
            state.language = null
        },
        resetAddLanguageResponse: (state) => {
            state.addLanguage = null
        },
        resetGetLanguageResponse: (state) => {
            state.getLanguage = null
        },
        resetEditLanguageResponse: (state) => {
            state.editLanguage = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionLanguage.pending, (state) => {
                state.loading = true
            })
            .addCase(actionLanguage.fulfilled, (state, action) => {
                state.loading = false
                state.language = action.payload
            })
            .addCase(actionLanguage.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddLanguage.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddLanguage.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addLanguage = action.payload
            })
            .addCase(actionAddLanguage.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetLanguage.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetLanguage.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getLanguage = action.payload
            })
            .addCase(actionGetLanguage.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditLanguage.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditLanguage.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editLanguage = action.payload
            })
            .addCase(actionEditLanguage.rejected, (state) => {
                state.loadingEdit = false
            })
    }
})
export const { resetLanguageResponse, resetAddLanguageResponse, resetGetLanguageResponse, resetEditLanguageResponse } = appLanguageSlice.actions

export default appLanguageSlice.reducer