// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { ADD_STATE, DISTRICT_WISE, EDIT_STATE, GET_STATE, STATE_LIST, STATE_WISE, TEHSIL_WISE } from '../../common/constant'

export const actionState = createAsyncThunk('appState/actionState', async (params, thunkAPI) => {
    return await post(STATE_LIST, params, thunkAPI)
})

export const actionAddState = createAsyncThunk('appState/actionAddState', async (params, thunkAPI) => {
    return await put(ADD_STATE, params, thunkAPI)
})

export const actionGetState = createAsyncThunk('appState/actionGetState', async (params, thunkAPI) => {
    return await post(GET_STATE, params, thunkAPI)
})

export const actionEditState = createAsyncThunk('appState/actionEditState', async (params, thunkAPI) => {
    return await post(EDIT_STATE, params, thunkAPI)
})

export const actionStateWise = createAsyncThunk('appState/actionStateWise', async (params, thunkAPI) => {
    return await post(STATE_WISE, params, thunkAPI)
})

export const actionDistrictWise = createAsyncThunk('appState/actionDistrictWise', async (params, thunkAPI) => {
    return await post(DISTRICT_WISE, params, thunkAPI)
})

export const actionTehsilWise = createAsyncThunk('appState/actionTehsilWise', async (params, thunkAPI) => {
    return await post(TEHSIL_WISE, params, thunkAPI)
})

export const appStateSlice = createSlice({
    name: 'appState',
    initialState: {
        //list
        loading: false,
        state: null,

        //add
        loadingAdd: false,
        addState: null,

        //get
        loadingGet: false,
        getState: null,

        //edit
        loadingEdit: false,
        editState: null,

        //State Wise District list
        loadingStateWise: false,
        getStateWise: null,

        //District Wise Tehsils list
        loadingDistrictWise: false,
        getDistrictWise: null,

        //Tehsil Wise village list
        loadingTehsilWise: false,
        getTehsilWise: null
    },
    reducers: {
        //reset or delete Add Insurance State store data
        resetStateResponse: (state) => {
            state.state = null
        },
        resetAddStateResponse: (state) => {
            state.addState = null
        },
        resetGetStateResponse: (state) => {
            state.getState = null
        },
        resetEditStateResponse: (state) => {
            state.editState = null
        },
        resetGetStateWiseResponse: (state) => {
            state.getStateWise = null
        },
        resetGetDistrictWiseResponse: (state) => {
            state.getDistrictWise = null
        },
        resetGetTehsilWiseResponse: (state) => {
            state.getTehsilWise = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionState.pending, (state) => {
                state.loading = true
            })
            .addCase(actionState.fulfilled, (state, action) => {
                state.loading = false
                state.state = action.payload
            })
            .addCase(actionState.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddState.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddState.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addState = action.payload
            })
            .addCase(actionAddState.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetState.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetState.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getState = action.payload
            })
            .addCase(actionGetState.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditState.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditState.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editState = action.payload
            })
            .addCase(actionEditState.rejected, (state) => {
                state.loadingEdit = false
            })

            // get district state wise
            .addCase(actionStateWise.pending, (state) => {
                state.loadingStateWise = true
            })
            .addCase(actionStateWise.fulfilled, (state, action) => {
                state.loadingStateWise = false
                state.getStateWise = action.payload
            })
            .addCase(actionStateWise.rejected, (state) => {
                state.loadingStateWise = false
            })

            // get tehsils district wise
            .addCase(actionDistrictWise.pending, (state) => {
                state.loadingDistrictWise = true
            })
            .addCase(actionDistrictWise.fulfilled, (state, action) => {
                state.loadingDistrictWise = false
                state.getDistrictWise = action.payload
            })
            .addCase(actionDistrictWise.rejected, (state) => {
                state.loadingDistrictWise = false
            })

            // get village tehsils wise
            .addCase(actionTehsilWise.pending, (state) => {
                state.loadingTehsilWise = true
            })
            .addCase(actionTehsilWise.fulfilled, (state, action) => {
                state.loadingTehsilWise = false
                state.getTehsilWise = action.payload
            })
            .addCase(actionTehsilWise.rejected, (state) => {
                state.loadingTehsilWise = false
            })
    }
})
export const { resetStateResponse, resetAddStateResponse, resetGetStateResponse, resetEditStateResponse, resetGetStateWiseResponse, getDistrictWise, getTehsilWise } = appStateSlice.actions

export default appStateSlice.reducer