// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { ADD_COUNTRY, COUNTRY, EDIT_COUNTRY, GET_COUNTRY } from '../../common/constant'

export const actionCountry = createAsyncThunk('appCountry/actionCountry', async (thunkAPI) => {
    return await post(COUNTRY, thunkAPI)
})

export const actionAddCountry = createAsyncThunk('appCountry/actionAddCountry', async (params, thunkAPI) => {
    return await put(ADD_COUNTRY, params, thunkAPI)
})

export const actionGetCountry = createAsyncThunk('appCountry/actionGetCountry', async (params, thunkAPI) => {
    return await post(GET_COUNTRY, params, thunkAPI)
})

export const actionEditCountry = createAsyncThunk('appCountry/actionEditCountry', async (params, thunkAPI) => {
    return await post(EDIT_COUNTRY, params, thunkAPI)
})

export const appCountrySlice = createSlice({
    name: 'appCountry',
    initialState: {
        //list
        loading: false,
        country: null,

        //add
        loadingAdd: false,
        addCountry: null,

        //get
        loadingGet: false,
        getCountry: null,

        //edit
        loadingEdit: false,
        editCountry: null

    },
    reducers: {
        //reset or delete Add Country store data
        resetCountryResponse: (state) => {
            state.country = null
        },
        resetAddCountryResponse: (state) => {
            state.addCountry = null
        },
        resetGetCountryResponse: (state) => {
            state.getCountry = null
        },
        resetEditCountryResponse: (state) => {
            state.editCountry = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionCountry.pending, (state) => {
                state.loading = true
            })
            .addCase(actionCountry.fulfilled, (state, action) => {
                state.loading = false
                state.country = action.payload
            })
            .addCase(actionCountry.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddCountry.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddCountry.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addCountry = action.payload
            })
            .addCase(actionAddCountry.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetCountry.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetCountry.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getCountry = action.payload
            })
            .addCase(actionGetCountry.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditCountry.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditCountry.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editCountry = action.payload
            })
            .addCase(actionEditCountry.rejected, (state) => {
                state.loadingEdit = false
            })
    }
})
export const { resetCountryResponse, resetAddCountryResponse, resetGetCountryResponse, resetEditCountryResponse } = appCountrySlice.actions

export default appCountrySlice.reducer