// API CONSTANTS

export const ENV = 'prod'

export const HOST = ENV === 'prod' ? 'https://admin.sharyat.com' : 'http://15.207.182.121'
//export const HOST = ENV === 'dev' ? 'http://localhost:4200' : 'http://localhost:4200'

export const API = '/api/sharyat/admin'
export const API_PARTNER = '/api/sharyat/partner'
export const LOGIN = `${HOST}${API}/login`
export const isDataModeOn = false

//User Add section 
export const USER = `${HOST}${API}/user/list`
export const ADD_USER = `${HOST}${API}/user/add`
export const EDIT_USER = `${HOST}${API}/user/edit`
export const GET_USER = `${HOST}${API}/user/get`
export const PASSWORD_CHANGE = `${HOST}${API}/user/passwordChange`

export const PRE_USER = `${HOST}${API}/user/pre-register/list`


export const GET_USER_CREATED_BY = `${HOST}${API}/user/created-by`

// COUNTRY Routes
export const COUNTRY = `${HOST}${API}/country/list`
export const ADD_COUNTRY = `${HOST}${API}/country/add`
export const EDIT_COUNTRY = `${HOST}${API}/country/edit`
export const GET_COUNTRY = `${HOST}${API}/country/get`
export const DELETE_COUNTRY = `${HOST}${API}/country/delete`

// STATE Routes
export const STATE_LIST = `${HOST}${API}/state/list`
export const ADD_STATE = `${HOST}${API}/state/add`
export const EDIT_STATE = `${HOST}${API}/state/edit`
export const GET_STATE = `${HOST}${API}/state/get`
export const DELETE_STATE = `${HOST}${API}/state/delete`

// GET STATE WISE
export const STATE_WISE = `${HOST}${API}/state/districts`
export const DISTRICT_WISE = `${HOST}${API}/tehsil`
export const TEHSIL_WISE = `${HOST}${API}/village`

// DISTRICT Routes
export const DISTRICT = `${HOST}${API}/district/list`
export const ADD_DISTRICT = `${HOST}${API}/district/add`
export const EDIT_DISTRICT = `${HOST}${API}/district/edit`
export const GET_DISTRICT = `${HOST}${API}/district/get`
export const DELETE_DISTRICT = `${HOST}${API}/district/delete`

// DISTRICT Routes
export const TEHSIL = `${HOST}${API}/tehsil/list`
export const ADD_TEHSIL = `${HOST}${API}/tehsil/add`
export const EDIT_TEHSIL = `${HOST}${API}/tehsil/edit`
export const GET_TEHSIL = `${HOST}${API}/tehsil/get`
export const DELETE_TEHSIL = `${HOST}${API}/tehsil/delete`

// VILLAGE Routes
export const VILLAGE = `${HOST}${API}/village/list`
export const ADD_VILLAGE = `${HOST}${API}/village/add`
export const EDIT_VILLAGE = `${HOST}${API}/village/edit`
export const GET_VILLAGE = `${HOST}${API}/village/get`
export const DELETE_VILLAGE = `${HOST}${API}/village/delete`
export const NEW_VILLAGE = `${HOST}${API}/village/missing-get`

// LANGUAGE Routes
export const LANGUAGE = `${HOST}${API}/language/list`
export const ADD_LANGUAGE = `${HOST}${API}/language/add`
export const EDIT_LANGUAGE = `${HOST}${API}/language/edit`
export const GET_LANGUAGE = `${HOST}${API}/language/get`

// Prize DMS
export const PRIZE_DMS_LIST = `${HOST}${API}/dms/list`
export const PRIZE_DMS_ADD = `${HOST}${API}/dms/add`
export const PRIZE_DMS_DELETE = `${HOST}${API}/dms/delete`
export const PRIZE_DMS_EDIT = `${HOST}${API}/dms/edit`
export const PRIZE_DMS_DETAILS = `${HOST}${API}/dms/details`
export const PRIZE_DMS_DROPDOWN_LIST = `${HOST}${API}/dms-list`

// Advertisement dms
export const ADVERTISMENT_DMS_LIST = `${HOST}${API}/advertisement/list`
export const ADVERTISEMENT_DMS_ADD = `${HOST}${API}/advertisement/add`
export const ADVERTISEMENT_DMS_DETAILS = `${HOST}${API}/advertisement/details`
export const ADVERTISEMENT_DMS_EDIT = `${HOST}${API}/advertisement/edit`
export const ADVERTISEMENT_DMS_DELETE = `${HOST}${API}/advertisement/remove`
export const PREMIUM_ADVERTISEMENT_LIST = `${HOST}${API}/advertisement/premium-advertisement-list`

// Bull Information
export const BULL_INFORMATION = `${HOST}${API}/bull/list`
export const ADD_BULL_INFORMATION = `${HOST}${API}/bull/add `
export const GET_BULL_INFORMATION = `${HOST}${API}/bull/get`
export const EDIT_BULL_INFORMATION = `${HOST}${API}/bull/edit`
export const DELETE_BULL_INFORMATION = `${HOST}${API}/bull/delete`

// Add Total Race Information
export const TOTAL_RACE_INFORMATION = `${HOST}${API}/race/list`
export const ADD_TOTAL_RACE_INFORMATION = `${HOST}${API}/race/add `
export const GET_TOTAL_RACE_INFORMATION = `${HOST}${API}/race/get`
export const EDIT_TOTAL_RACE_INFORMATION = `${HOST}${API}/race/edit`
export const DELETE_TOTAL_RACE_INFORMATION = `${HOST}${API}/race/delete`

// Add FINAL Race Information
export const TOTAL_FINAL_RACE_INFORMATION = `${HOST}${API}/final/list`
export const ADD_TOTAL_FINAL_RACE_INFORMATION = `${HOST}${API}/final/add `
export const GET_TOTAL_FINAL_RACE_INFORMATION = `${HOST}${API}/final/get`
export const EDIT_TOTAL_FINAL_RACE_INFORMATION = `${HOST}${API}/final/edit`
export const DELETE_TOTAL_FINAL_RACE_INFORMATION = `${HOST}${API}/final/delete`

//event section 
export const EVENT_ADD = `${HOST}${API}/event/add`
export const EVENTS = `${HOST}${API}/event/list`
export const EVENT_DETAILS = `${HOST}${API}/event/details`
export const EVENT_EDIT = `${HOST}${API}/event/edit`
export const EVENT_DELETE = `${HOST}${API}/event/delete`
export const EVENTS_LIST = `${HOST}${API}/event/dropdown-list`
export const EVENTS_APPROVAL = `${HOST}${API}/event/approved/status`
export const EVENTS_REMOVE = `${HOST}${API}/event/remove`
export const EVENTS_LIVE_STATUS = `${HOST}${API}/event/live/status`

//game type section
export const GAME_TYPE = `${HOST}${API}/game-type/list`
export const GAME_TYPE_ADD = `${HOST}${API}/game-type/add`
export const GAME_TYPE_DETAIL = `${HOST}${API}/game-type/details`
export const GAME_TYPE_EDIT = `${HOST}${API}/game-type/edit`

//event section 
export const EVENT_PRIZE_LIST = `${HOST}${API}/event-prize/list`
export const EVENT_PRIZES = `${HOST}${API}/event-prizes`
export const EVENT_PRIZE_ADD = `${HOST}${API}/event-prize/add`
export const EVENT_PRIZE_EDIT = `${HOST}${API}/event-prize/edit`
export const EVENT_PRIZE_DETAILS = `${HOST}${API}/event-prize/details`
export const EVENT_PRIZE_MASTER_LIST = `${HOST}${API}/event-prize/get`

export const EVENT_WINNING_RULES = `${HOST}${API}/event-winning-rule/list`
export const ADD_EVENT_WINNING_RULE = `${HOST}${API}/event-winning-rule/add`
export const EDIT_EVENT_WINNING_RULE = `${HOST}${API}/event-winning-rule/edit`

export const EVENT_ORGANIZER = `${HOST}${API}/event-organizer/list`
export const EVENT_ORGANIZER_ADD = `${HOST}${API}/event-organizer/add`
export const EVENT_ORGANIZER_EDIT = `${HOST}${API}/event-organizer/edit`
export const EVENT_ORGANIZER_DETAILS = `${HOST}${API}/event-organizer/details`
export const EVENT_ORGANIZER_DELETE = `${HOST}${API}/event-organizer/remove`
export const EVENT_ORGANIZER_SEARCH = `${HOST}${API}/event-organizer/user/search`

export const SEARCH_USER_BY_MOBILE = `${HOST}${API}/search-user-by-mobile`

export const EVENT_PARTICIPANTS = `${HOST}${API}/event-participant/list`
export const EVENT_PARTICIPANT_ADD = `${HOST}${API}/event-participant/add`
export const EVENT_PARTICIPANT_EDIT = `${HOST}${API}/event-participant/edit`
export const EVENT_PARTICIPANT_DETAILS = `${HOST}${API}/event-participant/details`
export const GENERATE_TOKEN = `${HOST}${API}/event-participant/generate-token`
export const EVENT_PARTICIPANT_GENERATE_ROUND = `${HOST}${API}/event-participant/generate-round`

export const EVENT_RULES = `${HOST}${API}/event/update/rules`
export const EVENT_RULES_DETAILS = `${HOST}${API}/event/details/rules`

// Move User from one round to another
export const MOVE_PARTICIPANT_FROM_ROUND = `${HOST}${API}/event-rounds/update-participants-round`
// Move Participant to Round
export const PARTICIPANT_MOVE_TO_ROUND = `${HOST}${API}/event-rounds/move-participant`

export const EVENT_ROUNDS = `${HOST}${API}/event-rounds/list`
export const EVENT_ROUND_PARTICIPANTS = `${HOST}${API}/event-rounds/participants/multiple`
export const EVENT_ROUNDS_ADD = `${HOST}${API}/event-rounds/add`
export const EVENT_ROUNDS_PARTICIPANTS = `${HOST}${API}/event-rounds/participants`
export const ROUND_PARTICIPANTS_SCORE_ADD = `${HOST}${API}/event-scores/update-score`
export const ROUND_PARTICIPANTS_SCORE_EDIT = `${HOST}${API}/event-rounds/participants`

export const EVENT_ROUND_UPDATE_STATUS = `${HOST}${API}/event-rounds/update/status`
export const EVENT_ROUND_PARTICIPANT_ASSIGN_PRIZE = `${HOST}${API}/event-score-prizes/assign`
export const EVENT_ROUND_PRIZES = `${HOST}${API}/event-round-prizes/list`
export const EVENT_PARTICIPANT_ADD_TO_ROUND = `${HOST}${API}/event-rounds/add-participant-to-round`
export const EVENT_PARTICIPANT_ROUND_LIST = `${HOST}${API}/event-rounds/participant-list`

export const EVENT_UPDATE_STATUS = `${HOST}${API}/event/update-status`
export const EVENT_PRIZE_DELETE = `${HOST}${API}/event-prize/remove`
export const EVENT_ROUND_PARTICIPANT_SWAP = `${HOST}${API}/event-rounds/swap-round-participants`
export const EVENT_PARTICIPANT_DELETE = `${HOST}${API}/event-participant/remove`
export const USER_SEARCH = `${HOST}${API}/search-user-by-first-or-middle-or-last-name`
export const EVENT_PARTICIPANT_TOKEN_UPDATE = `${HOST}${API}/event-participant/update-token`
export const UPLOAD_CERTIFICATE = `${HOST}${API}/event-score-prizes/upload-certificate`
export const EVENT_ROUND_EDIT = `${HOST}${API}/event-rounds/edit`
export const EVENT_ROUND_EDIT_DETAILS = `${HOST}${API}/event-rounds/details`
export const EVENT_PARTICIPANT_ROUND_SHIFT = `${HOST}${API}/event-rounds/update-participants-round`
export const RACE_TIME_WISE_PARTICIPANTS = `${HOST}${API}/event-rounds/race-time-wise-participants`
export const UPDATE_CURRENT_TOKEN = `${HOST}${API}/event/update-current-token`
export const ASSIGN_ADVERTISEMENT_TO_EVENT = `${HOST}${API}/advertisement/attached-advertisement-to-event`
export const ATTACHED_ADS = `${HOST}${API}/event/attached-ads`
export const ROUND_PARTICIPANT_DELETE = `${HOST}${API}/event-scores/participant/remove`

//event image doc
export const EVENT_GET_DOCUMENTS = `${HOST}${API}/event/documents`
export const EVENT_ADD_DOCUMENTS = `${HOST}${API}/event/document/add`
export const EVENT_DETAILS_DOCUMENTS = `${HOST}${API}/event/document/details`
export const EVENT_EDIT_DOCUMENTS = `${HOST}${API}/event/document/edit`
export const EVENT_DELETE_DOCUMENTS = `${HOST}${API}/event/document/delete`

export const PHOTO_VIDEO_USER_LIST = `${HOST}${API}/photographer-or-videographer-list`
// Event Photo
export const EVENT_PHOTO_LIST = `${HOST}${API}/event-photo/list`
export const EVENT_PHOTO_ADD = `${HOST}${API}/event-photo/add`
export const EVENT_PHOTO_DELETE = `${HOST}${API}/event-photo/remove`
// Event Video
export const EVENT_VIDEO_LIST = `${HOST}${API}/event-video/list`
export const EVENT_VIDEO_ADD = `${HOST}${API}/event-video/add`
export const EVENT_VIDEO_DELETE = `${HOST}${API}/event-video/remove`

// Participant Report
export const EVENT_PARTICIPANT_REPORT = `${HOST}${API}/reports/event-participants`
export const EVENT_PARTICIPANT_ROUND_REPORT = `${HOST}${API}/reports/event-round`
export const EVENT_ROUND_PARTICIPANT_DETAILS_REPORT = `${HOST}${API}/reports/event-rounds/round-participant-report`

// Social Icons
export const SOCIAL_ICONS = `${HOST}${API}/social_icon/list`
export const ADD_STICKERS = `${HOST}${API}/social_icon/add`
export const EDIT_STICKERS = `${HOST}${API}/social_icon/edit`
export const DELETE_STICKERS = `${HOST}${API}/social_icon/delete`

//POST 
export const POST_LIST = `${HOST}${API}/post/list`
export const POST_ADD = `${HOST}${API}/post/add`
export const POST_EDIT = `${HOST}${API}/post/edit`
export const POST_DETAILS = `${HOST}${API}/post/details`
export const POST_DELETE = `${HOST}${API}/post/delete`
export const POST_REPORT_COUNT = `${HOST}${API}/post/reportCount`
export const BOOST_POST_LIST = `${HOST}${API}/post/boost-post-list`

//LIKE & COMMENT & report LIST
export const POST_GET_LIKE = `${HOST}${API}/post-likes/list`
export const POST_GET_COMMENT = `${HOST}${API}/post-Comments/list`
export const POST_GET_REPORT = `${HOST}${API}/post-report/list`
export const POST_COMMENT_REMOVE = `${HOST}${API}/post-comments/remove`
export const POST_REMOVE = `${HOST}${API}/post/remove`

//Prize Type 
export const PRIZE_TYPE_LIST = `${HOST}${API}/prize-type/list`
export const PRIZE_TYPE_ADD = `${HOST}${API}/prize-type/add`
export const PRIZE_TYPE_DETAILS = `${HOST}${API}/prize-type/details`
export const PRIZE_TYPE_EDIT = `${HOST}${API}/prize-type/edit`

//notification master 
export const NOTIFICATION_MASTER_LIST = `${HOST}${API}/notification-masters/list`
export const NOTIFICATION_MASTER_ADD = `${HOST}${API}/notification-masters/add`
export const NOTIFICATION_MASTER_DETAILS = `${HOST}${API}/notification-masters/details`
export const NOTIFICATION_MASTER_EDIT = `${HOST}${API}/notification-masters/edit`

//report master 
export const REPORT_MASTER_LIST = `${HOST}${API}/report-masters/list`
export const REPORT_MASTER_ADD = `${HOST}${API}/report-masters/add`
export const REPORT_MASTER_DETAIL = `${HOST}${API}/report-masters/details`
export const REPORT_MASTER_EDIT = `${HOST}${API}/report-masters/edit`

//dashboard
export const DASHBOARD_COUNT = `${HOST}${API}/dashboard/count`
export const DASHBOARD_TODAYS_EVENT_LIST = `${HOST}${API}/dashboard/todays/Event/List`
export const DASHBOARD_TOTAL_USER_LIST = `${HOST}${API}/dashboard/total/User/List`
export const DASHBOARD_TOTAL_POST_LIST = `${HOST}${API}/dashboard/total/post/List`
export const DASHBOARD_TOTAL_RACE_LIST = `${HOST}${API}/dashboard/total/race/List`
export const FEEDBACK_LIST = `${HOST}${API}/dashboard/feedback`

//new dashboard
export const DASHBOARD_PROFILE_WISE_USER = `${HOST}${API}/dashboard/profile/wise`
export const DASHBOARD_TOTAL_POST_TYPE = `${HOST}${API}/dashboard/post/type/total` //post type api

//setting 
export const SETTING_LIST = `${HOST}${API}/setting-masters/list`
export const SETTING_ADD = `${HOST}${API}/setting-masters/add`
export const SETTING_DETAILS = `${HOST}${API}/setting-masters/detail`
export const SETTING_EDIT = `${HOST}${API}/setting-masters/edit`

//Reviews
export const REVIEW_LIST = `${HOST}${API}/review/list`

//Directory
export const DIRECTORY_LIST = `${HOST}${API}/directories/list`

export const NOTIFICATION = `${HOST}${API}/admin-notification/list`
export const ADD_NOTIFICATION = `${HOST}${API}/admin-notification/add`
export const EDIT_NOTIFICATION = `${HOST}${API}/admin-notification/edit`
export const GET_NOTIFICATION = `${HOST}${API}/admin-notification/details`
export const SEND_NOTIFICATION = `${HOST}${API}/admin-notification/send-notification`
export const DELETE_NOTIFICATION = `${HOST}${API}/admin-notification/remove`

export const CHANNEL = `${HOST}${API}/youtube-channel/list`
export const ADD_CHANNEL = `${HOST}${API}/youtube-channel/add`
export const EDIT_CHANNEL = `${HOST}${API}/youtube-channel/edit`
export const GET_CHANNEL = `${HOST}${API}/youtube-channel/detail`
export const DELETE_CHANNEL = `${HOST}${API}/youtube-channel/remove`
// export const SEND_VIDEO = `${HOST}${API}/admin-video/send-notification`

export const ADD_LINK = `${HOST}${API}/youtube-link/add`
export const GET_LINK = `${HOST}${API}/youtube-link/detail`
export const EDIT_LINK = `${HOST}${API}/youtube-link/edit`
export const LIST_LINK = `${HOST}${API}/youtube-link/list`
export const DELETE_LINK = `${HOST}${API}/youtube-link/remove`
//Report Management
export const FINAL_REPORT_RESULT_2 = `${HOST}${API_PARTNER}/pdf/finalRoundResultPDF`
export const EVENT_ROUND_LIST = `${HOST}${API}/event-round/dropdown-list`

export const FINAL_REPORT_RESULT_3 = `${HOST}${API_PARTNER}/pdf/tokenListPDf`
export const FINAL_REPORT_RESULT_5 = `${HOST}${API_PARTNER}/pdf/tokenResultSheetPDf`
export const FINAL_REPORT_RESULT_4 = `${HOST}${API_PARTNER}/pdf/roundResultPDF`
export const FINAL_REPORT_RESULT_1 = `${HOST}${API_PARTNER}/pdf/pageWiseRoundResultPDF`
//Event Add Description
export const ADD_DESCRIPTION = `${HOST}${API}/event/add-description`
