export const TOAST_DURATION = 4000
export const TOAST_POSITION = 'bottom-right'
export const API_TIMEOUT = 5000
export const AGE_MIN_LIMIT = 18
export const AGE_MAX_LIMIT = 100
export const UNAUTHORIZED = 400
export const ERROR = 402
export const TWO_MB = 2097152   //2 mb in bytes
export const FIVE_MB = 5242880   //5 mb in bytes
export const TEN_MB = 10485760   //10 mb in bytes
export const TEN_MB_PLUS_ONE = 10485761   //10 mb in bytes + 1 byte
export const TWENTY_FIVE_MB = 26214400   //25 mb in bytes
export const TWENTY_FIVE_MB_PLUS_ONE = 26214401   //25 mb in bytes
export const SECRET_KEY = 'a68eac876b1eX4169296d39a7ad66jun'
export const RUPEE_SYMBOL = '₹'

export const USER_TITLES = [
    { value: 'श्री', label: 'श्री' },
    { value: 'पै', label: 'पै' },
    { value: 'कु', label: 'कु' },
    { value: 'कै ', label: 'कै' },
    { value: 'ह.भ.प', label: 'ह.भ.प' },
    { value: 'ॲड.', label: 'ॲड.' },
    { value: 'डॉ ', label: 'डॉ ' },
    { value: 'सौ  ', label: 'सौ' }
]