// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { ADD_DISTRICT, EDIT_DISTRICT, GET_DISTRICT, DISTRICT } from '../../common/constant'

export const actionDistrict = createAsyncThunk('appDistrict/actionDistrict', async (params, thunkAPI) => {
    return await post(DISTRICT, params, thunkAPI)
})

export const actionAddDistrict = createAsyncThunk('appDistrict/actionAddDistrict', async (params, thunkAPI) => {
    return await put(ADD_DISTRICT, params, thunkAPI)
})

export const actionGetDistrict = createAsyncThunk('appDistrict/actionGetDistrict', async (params, thunkAPI) => {
    return await post(GET_DISTRICT, params, thunkAPI)
})

export const actionEditDistrict = createAsyncThunk('appDistrict/actionEditDistrict', async (params, thunkAPI) => {
    return await post(EDIT_DISTRICT, params, thunkAPI)
})

export const appDistrictSlice = createSlice({
    name: 'appDistrict',
    initialState: {
        //list
        loading: false,
        district: null,

        //add
        loadingAdd: false,
        addDistrict: null,

        //get
        loadingGet: false,
        getDistrict: null,

        //edit
        loadingEdit: false,
        editDistrict: null

    },
    reducers: {
        //reset or delete Add District store data
        resetDistrictResponse: (state) => {
            state.district = null
        },
        resetAddDistrictResponse: (state) => {
            state.addDistrict = null
        },
        resetGetDistrictResponse: (state) => {
            state.getDistrict = null
        },
        resetEditDistrictResponse: (state) => {
            state.editDistrict = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionDistrict.pending, (state) => {
                state.loading = true
            })
            .addCase(actionDistrict.fulfilled, (state, action) => {
                state.loading = false
                state.district = action.payload
            })
            .addCase(actionDistrict.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddDistrict.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddDistrict.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addDistrict = action.payload
            })
            .addCase(actionAddDistrict.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetDistrict.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetDistrict.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getDistrict = action.payload
            })
            .addCase(actionGetDistrict.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditDistrict.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditDistrict.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editDistrict = action.payload
            })
            .addCase(actionEditDistrict.rejected, (state) => {
                state.loadingEdit = false
            })
    }
})
export const { resetDistrictResponse, resetAddDistrictResponse, resetGetDistrictResponse, resetEditDistrictResponse } = appDistrictSlice.actions

export default appDistrictSlice.reducer