import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { GAME_TYPE, GAME_TYPE_ADD, GAME_TYPE_DETAIL, GAME_TYPE_EDIT } from '../../common/constant'
//** import apis

//List
export const actionGameTypeList = createAsyncThunk('gameType/actionGameTypeList', async (params, thunkAPI) => {
    return await post(GAME_TYPE, params, thunkAPI)
})

//add
export const actionGameTypeAdd = createAsyncThunk('gameType/actionGameTypeAdd', async (params, thunkAPI) => {
    return await post(GAME_TYPE_ADD, params, thunkAPI)
})

//Details 
export const actionGameTypeDetail = createAsyncThunk('gameType/actionGameTypeDetail', async (params, thunkAPI) => {
    return await post(GAME_TYPE_DETAIL, params, thunkAPI)
})

//Edit 
export const actionGameTypeEdit = createAsyncThunk('gameType/actionGameTypeEdit', async (params, thunkAPI) => {
    return await post(GAME_TYPE_EDIT, params, thunkAPI)
})

export const gameTypeSlice = createSlice({
    name: 'gameType',
    initialState: {
        //list
        loadingGameTypeList: false,
        gameTypeList: null,

        //Add
        loadingGameTypeAdd: false,
        gameTypeAdd: null,

        //Details 
        loadingGameTypeDetail: false,
        gameTypeDetail: null,

        //Edit 
        loadingGameTypeEdit: false,
        gameTypeEdit: null

    },
    reducers: {
        // List store
        resetGameTypeListResponse: (state) => {
            state.gameTypeList = null
        },

        //Add
        resetGameTypeAddResponse: (state) => {
            state.gameTypeAdd = null
        },

        //Details
        resetGameTypeDetailResponse: (state) => {
            state.gameTypeDetail = null
        },

        //Edit
        resetGameTypeEditResponse: (state) => {
            state.gameTypeEdit = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionGameTypeList.pending, (state) => {
                state.loadingGameTypeList = true
            })
            .addCase(actionGameTypeList.fulfilled, (state, action) => {
                state.loadingGameTypeList = false
                state.gameTypeList = action.payload
            })
            .addCase(actionGameTypeList.rejected, (state) => {
                state.loadingGameTypeList = false
            })

            //Add
            .addCase(actionGameTypeAdd.pending, (state) => {
                state.loadingGameTypeAdd = true
            })
            .addCase(actionGameTypeAdd.fulfilled, (state, action) => {
                state.loadingGameTypeAdd = false
                state.gameTypeAdd = action.payload
            })
            .addCase(actionGameTypeAdd.rejected, (state) => {
                state.loadingGameTypeAdd = false
            })

            //Details
            .addCase(actionGameTypeDetail.pending, (state) => {
                state.loadingGameTypeDetail = true
            })
            .addCase(actionGameTypeDetail.fulfilled, (state, action) => {
                state.loadingGameTypeDetail = false
                state.gameTypeDetail = action.payload
            })
            .addCase(actionGameTypeDetail.rejected, (state) => {
                state.loadingGameTypeDetail = false
            })

            //Details
            .addCase(actionGameTypeEdit.pending, (state) => {
                state.loadingGameTypeEdit = true
            })
            .addCase(actionGameTypeEdit.fulfilled, (state, action) => {
                state.loadingGameTypeEdit = false
                state.gameTypeEdit = action.payload
            })
            .addCase(actionGameTypeEdit.rejected, (state) => {
                state.loadingGameTypeEdit = false
            })
    }
})
export const { resetGameTypeListResponse, resetGameTypeAddResponse, resetGameTypeDetailResponse, resetGameTypeEditResponse } = gameTypeSlice.actions

export default gameTypeSlice.reducer