import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../common/api'
import {PRIZE_TYPE_ADD, PRIZE_TYPE_DETAILS, PRIZE_TYPE_EDIT, PRIZE_TYPE_LIST } from '../../common/constant'
//** import apis

//List
export const actionPrizeTypeList = createAsyncThunk('prizeType/actionPrizeTypeList', async (params, thunkAPI) => {
    return await get(PRIZE_TYPE_LIST, params, thunkAPI)
})

//add
export const actionPrizeTypeAdd = createAsyncThunk('prizeType/actionPrizeTypeAdd', async (params, thunkAPI) => {
    return await post(PRIZE_TYPE_ADD, params, thunkAPI)
})

//details
export const actionPrizeTypeDetails = createAsyncThunk('prizeType/actionPrizeTypeDetails', async (params, thunkAPI) => {
    return await post(PRIZE_TYPE_DETAILS, params, thunkAPI)
})

//Edit
export const actionPrizeTypeEdit = createAsyncThunk('prizeType/actionPrizeTypeEdit', async (params, thunkAPI) => {
    return await post(PRIZE_TYPE_EDIT, params, thunkAPI)
})

export const prizeTypeSlice = createSlice({
    name: 'prizeType',
    initialState: {
        //list
        loadingPrizeTypeList: false,
        prizeTypeList: null,

        //Add
        loadingPrizeTypeAdd: false,
        prizeTypeAdd: null,

        //Details
        loadingPrizeTypeDetails: false,
        prizeTypeDetails: null,

        //Edit
        loadingPrizeTypeEdit: false,
        prizeTypeEdit: null
    },
    reducers: {
        // List store
        resetPrizeTypeListResponse: (state) => {
            state.prizeTypeList = null
        },

        // Add store
        resetPrizeTypeAddResponse: (state) => {
            state.prizeTypeAdd = null
        },

        // Details store
        resetPrizeTypeDetailsResponse: (state) => {
            state.prizeTypeDetails = null
        },

        // Edit store
        resetPrizeTypeEditResponse: (state) => {
            state.prizeTypeEdit = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionPrizeTypeList.pending, (state) => {
                state.loadingPrizeTypeList = true
            })
            .addCase(actionPrizeTypeList.fulfilled, (state, action) => {
                state.loadingPrizeTypeList = false
                state.prizeTypeList = action.payload
            })
            .addCase(actionPrizeTypeList.rejected, (state) => {
                state.loadingPrizeTypeList = false
            })

            //Add
            .addCase(actionPrizeTypeAdd.pending, (state) => {
                state.loadingPrizeTypeAdd = true
            })
            .addCase(actionPrizeTypeAdd.fulfilled, (state, action) => {
                state.loadingPrizeTypeAdd = false
                state.prizeTypeAdd = action.payload
            })
            .addCase(actionPrizeTypeAdd.rejected, (state) => {
                state.loadingPrizeTypeAdd = false
            })

            //Details
            .addCase(actionPrizeTypeDetails.pending, (state) => {
                state.loadingPrizeTypeDetails = true
            })
            .addCase(actionPrizeTypeDetails.fulfilled, (state, action) => {
                state.loadingPrizeTypeDetails = false
                state.prizeTypeDetails = action.payload
            })
            .addCase(actionPrizeTypeDetails.rejected, (state) => {
                state.loadingPrizeTypeDetails = false
            })

            //Details
            .addCase(actionPrizeTypeEdit.pending, (state) => {
                state.loadingPrizeTypeEdit = true
            })
            .addCase(actionPrizeTypeEdit.fulfilled, (state, action) => {
                state.loadingPrizeTypeEdit = false
                state.prizeTypeEdit = action.payload
            })
            .addCase(actionPrizeTypeEdit.rejected, (state) => {
                state.loadingPrizeTypeEdit = false
            })
    }
})
export const { resetPrizeTypeListResponse, resetPrizeTypeAddResponse, resetPrizeTypeDetailsResponse, resetPrizeTypeEditResponse} = prizeTypeSlice.actions

export default prizeTypeSlice.reducer