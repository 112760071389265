// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { TOTAL_FINAL_RACE_INFORMATION, ADD_TOTAL_FINAL_RACE_INFORMATION, GET_TOTAL_FINAL_RACE_INFORMATION, EDIT_TOTAL_FINAL_RACE_INFORMATION, DELETE_TOTAL_FINAL_RACE_INFORMATION } from '../../common/constant'

export const actionFinalRaceInfo = createAsyncThunk('appFinalRaceInfo/actionFinalRaceInfo', async (thunkAPI) => {
    return await post(TOTAL_FINAL_RACE_INFORMATION, thunkAPI)
})

export const actionAddFinalRaceInfo = createAsyncThunk('appFinalRaceInfo/actionAddFinalRaceInfo', async (params, thunkAPI) => {
    return await put(ADD_TOTAL_FINAL_RACE_INFORMATION, params, thunkAPI)
})

export const actionGetFinalRaceInfo = createAsyncThunk('appFinalRaceInfo/actionGetFinalRaceInfo', async (params, thunkAPI) => {
    return await post(GET_TOTAL_FINAL_RACE_INFORMATION, params, thunkAPI)
})

export const actionEditFinalRaceInfo = createAsyncThunk('appFinalRaceInfo/actionEditFinalRaceInfo', async (params, thunkAPI) => {
    return await post(EDIT_TOTAL_FINAL_RACE_INFORMATION, params, thunkAPI)
})
export const actionDeleteFinalRaceInfo = createAsyncThunk('appFinalRaceInfo/actionDeleteFinalRaceInfo', async (params, thunkAPI) => {
    return await post(DELETE_TOTAL_FINAL_RACE_INFORMATION, params, thunkAPI)
})

export const appFinalRaceInfoSlice = createSlice({
    name: 'appFinalRaceInfo',
    initialState: {
        //list
        loading: false,
        totalFinalRaceInfo: null,

        //add
        loadingAdd: false,
        addFinalRaceInfo: null,

        //get
        loadingGet: false,
        getFinalRaceInfo: null,

        //edit
        loadingEdit: false,
        editFinalRaceInfo: null,

        //delete
        loadingDelete: false,
        deleteFinalRaceInfo: null
    },
    reducers: {
        //reset or delete Add totalFinalRaceInfo store data
        resetFinalRaceInfoResponse: (state) => {
            state.totalFinalRaceInfo = null
        },
        resetAddFinalRaceInfoResponse: (state) => {
            state.addFinalRaceInfo = null
        },
        resetGetFinalRaceInfoResponse: (state) => {
            state.getFinalRaceInfo = null
        },
        resetEditFinalRaceInfoResponse: (state) => {
            state.editFinalRaceInfo = null
        },
        resetDeleteFinalRaceInfoResponse: (state) => {
            state.deleteFinalRaceInfo = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionFinalRaceInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(actionFinalRaceInfo.fulfilled, (state, action) => {
                state.loading = false
                state.totalFinalRaceInfo = action.payload
            })
            .addCase(actionFinalRaceInfo.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddFinalRaceInfo.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddFinalRaceInfo.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addFinalRaceInfo = action.payload
            })
            .addCase(actionAddFinalRaceInfo.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetFinalRaceInfo.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetFinalRaceInfo.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getFinalRaceInfo = action.payload
            })
            .addCase(actionGetFinalRaceInfo.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditFinalRaceInfo.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditFinalRaceInfo.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editFinalRaceInfo = action.payload
            })
            .addCase(actionEditFinalRaceInfo.rejected, (state) => {
                state.loadingEdit = false
            })
            //delete
            .addCase(actionDeleteFinalRaceInfo.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteFinalRaceInfo.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteFinalRaceInfo = action.payload
            })
            .addCase(actionDeleteFinalRaceInfo.rejected, (state) => {
                state.loadingDelete = false
            })
    }
})
export const { resetFinalRaceInfoResponse, resetAddFinalRaceInfoResponse, resetGetFinalRaceInfoResponse, resetEditFinalRaceInfoResponse, resetDeleteFinalRaceInfoResponse } = appFinalRaceInfoSlice.actions

export default appFinalRaceInfoSlice.reducer