import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from "../../common/api"
import {
    EVENT_PRIZE_ADD,
    EVENT_PRIZE_EDIT,
    EVENT_PRIZE_LIST,
    EVENT_PRIZES,
    EVENT_ROUND_PARTICIPANT_ASSIGN_PRIZE,
    EVENT_ROUND_PRIZES,
    EVENT_PRIZE_DELETE,
    EVENT_PRIZE_DETAILS,
    EVENT_PRIZE_MASTER_LIST
} from '../../common/constant'

export const actionEventPrizeAdd = createAsyncThunk('appEventPrize/actionEventPrizeAdd', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_ADD, params, thunkAPI)
})

export const actionEventPrizeEdit = createAsyncThunk('appEventPrize/actionEventPrizeEdit', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_EDIT, params, thunkAPI)
})

// dropdown
export const actionEventPrizeList = createAsyncThunk('appEventPrize/actionEventPrizeList', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_LIST, params, thunkAPI)
})

export const actionEventPrizes = createAsyncThunk('appEventPrize/actionEventPrizes', async (thunkAPI) => {
    return await post(EVENT_PRIZES, thunkAPI)
})

export const actionEventRoundParticipantAssignPrize = createAsyncThunk('appEventPrize/actionEventRoundParticipantAssignPrize', async (params, thunkAPI) => {
    return await post(EVENT_ROUND_PARTICIPANT_ASSIGN_PRIZE, params, thunkAPI)
})

export const actionEventRoundPrizes = createAsyncThunk('appEventPrize/actionEventRoundPrizes', async (params, thunkAPI) => {
    return await post(EVENT_ROUND_PRIZES, params, thunkAPI)
})

export const actionEventPrizeDelete = createAsyncThunk('appEventPrize/actionEventPrizeDelete', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_DELETE, params, thunkAPI)
})
export const actionEventPrizeDetails = createAsyncThunk('appEventPrize/actionEventPrizeDetails', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_DETAILS, params, thunkAPI)
})

export const actionEventPrizeMasterList = createAsyncThunk('appEventPrize/actionEventPrizeMasterList', async (params, thunkAPI) => {
    return await post(EVENT_PRIZE_MASTER_LIST, params, thunkAPI)
})

export const appEventPrizeSlice = createSlice({
    name: 'appEventPrize',
    initialState: {
        //event prize 
        loadingEventPrizes: false,
        eventPrizes: null,

        //event prizes dropdown
        loadingEventPrizeList: false,
        eventPrizeList: null,

        //add event prize
        loadingAddEventPrize: false,
        addEventPrize: null,

        //edit event prize
        loadingEditEventPrize: false,
        editEventPrize: null,

        //EventRoundParticipantAssignPrize
        loadingEventRoundParticipantAssignPrize: false,
        eventRoundParticipantAssignPrize: null,

        //EventRoundPrizes
        loadingEventRoundPrizes: false,
        eventRoundPrizes: null,

        // Eventprizedelete
        loadingEventPrizeDelete: false,
        eventPrizeDelete: null,

        // Eventprizedetails
        loadingEventPrizeDetails: false,
        eventPrizeDetails: null,

        // EventPrizeMasterList
        loadingEventPrizeMasterList: false,
        eventPrizeMasterList: null

    },
    reducers: {
        // dropdown
        resetEventPrizeListResponse: (state) => {
            state.eventPrizeList = null
        },
        resetEventPrizesResponse: (state) => {
            state.eventPrizes = null
        },
        resetEventPrizeAddResponse: (state) => {
            state.addEventPrize = null
        },
        resetEventPrizeEditResponse: (state) => {
            state.editEventPrize = null
        },
        resetEventRoundParticipantAssignPrize: (state) => {
            state.eventRoundParticipantAssignPrize = null
        },
        resetEventRoundPrizes: (state) => {
            state.eventRoundPrizes = null
        },
        resetEventPrizeDelete: (state) => {
            state.eventPrizeDelete = null
        },
        resetEventPrizeDetails: (state) => {
            state.eventPrizeDetails = null
        },
        resetEventPrizeMasterList: (state) => {
            state.eventPrizeMasterList = null
        }
    },
    extraReducers: builder => {
        builder
            //event prizes
            .addCase(actionEventPrizes.pending, (state) => {
                state.loadingEventPrizes = true
            })
            .addCase(actionEventPrizes.fulfilled, (state, action) => {
                state.loadingEventPrizes = false
                state.eventPrizes = action.payload
            })
            .addCase(actionEventPrizes.rejected, (state) => {
                state.loadingEventPrizes = false
            })

            //event prizes dropdown
            .addCase(actionEventPrizeList.pending, (state) => {
                state.loadingEventPrizeList = true
            })
            .addCase(actionEventPrizeList.fulfilled, (state, action) => {
                state.loadingEventPrizeList = false
                state.eventPrizeList = action.payload
            })
            .addCase(actionEventPrizeList.rejected, (state) => {
                state.loadingEventPrizeList = false
            })

            //event prize add
            .addCase(actionEventPrizeAdd.pending, (state) => {
                state.loadingAddEventPrize = true
            })
            .addCase(actionEventPrizeAdd.fulfilled, (state, action) => {
                state.loadingAddEventPrize = false
                state.addEventPrize = action.payload
            })
            .addCase(actionEventPrizeAdd.rejected, (state) => {
                state.loadingAddEventPrize = false
            })

            //event prize edit
            .addCase(actionEventPrizeEdit.pending, (state) => {
                state.loadingEditEventPrize = true
            })
            .addCase(actionEventPrizeEdit.fulfilled, (state, action) => {
                state.loadingEditEventPrize = false
                state.editEventPrize = action.payload
            })
            .addCase(actionEventPrizeEdit.rejected, (state) => {
                state.loadingEditEventPrize = false
            })

            //eventRoundParticipantAssignPrize 
            .addCase(actionEventRoundParticipantAssignPrize.pending, (state) => {
                state.loadingEventRoundParticipantAssignPrize = true
            })
            .addCase(actionEventRoundParticipantAssignPrize.fulfilled, (state, action) => {
                state.loadingEventRoundParticipantAssignPrize = false
                state.eventRoundParticipantAssignPrize = action.payload
            })
            .addCase(actionEventRoundParticipantAssignPrize.rejected, (state) => {
                state.loadingEventRoundParticipantAssignPrize = false
            })

            //eventRoundPrizes
            .addCase(actionEventRoundPrizes.pending, (state) => {
                state.loadingEventRoundPrizes = true
            })
            .addCase(actionEventRoundPrizes.fulfilled, (state, action) => {
                state.loadingEventRoundPrizes = false
                state.eventRoundPrizes = action.payload
            })
            .addCase(actionEventRoundPrizes.rejected, (state) => {
                state.loadingEventRoundPrizes = false
            })

            //event prize delete
            .addCase(actionEventPrizeDelete.pending, (state) => {
                state.loadingEventPrizeDelete = true
            })
            .addCase(actionEventPrizeDelete.fulfilled, (state, action) => {
                state.loadingEventPrizeDelete = false
                state.eventPrizeDelete = action.payload
            })
            .addCase(actionEventPrizeDelete.rejected, (state) => {
                state.loadingEventPrizeDelete = false
            })

            //event prize delete
            .addCase(actionEventPrizeDetails.pending, (state) => {
                state.loadingEventPrizeDetails = true
            })
            .addCase(actionEventPrizeDetails.fulfilled, (state, action) => {
                state.loadingEventPrizeDetails = false
                state.eventPrizeDetails = action.payload
            })
            .addCase(actionEventPrizeDetails.rejected, (state) => {
                state.loadingEventPrizeDetails = false
            })

            //event prize Master List
            .addCase(actionEventPrizeMasterList.pending, (state) => {
                state.loadingEventPrizeMasterList = true
            })
            .addCase(actionEventPrizeMasterList.fulfilled, (state, action) => {
                state.loadingEventPrizeMasterList = false
                state.eventPrizeMasterList = action.payload
            })
            .addCase(actionEventPrizeMasterList.rejected, (state) => {
                state.loadingEventPrizeMasterList = false
            })
    }
})
export const {
    resetEventPrizeListResponse,
    resetEventPrizesResponse,
    resetEventPrizeAddResponse,
    resetEventPrizeEditResponse,
    resetEventRoundParticipantAssignPrize,
    resetEventRoundPrizes,
    resetEventPrizeDelete,
    resetEventPrizeDetails,
    resetEventPrizeMasterList
} = appEventPrizeSlice.actions

export default appEventPrizeSlice.reducer