// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { EVENT_ADD_DOCUMENTS, EVENT_DELETE_DOCUMENTS, EVENT_DETAILS_DOCUMENTS, EVENT_EDIT_DOCUMENTS, EVENT_GET_DOCUMENTS } from '../../common/constant'

//list image doc
export const actionEventsImageDocuments = createAsyncThunk('appEventImageDocument/actionEventsImageDocuments', async (params, thunkAPI) => {
    return await post(EVENT_GET_DOCUMENTS, params, thunkAPI)
})

//add event document
export const actionAddEventsDocuments = createAsyncThunk('appEventImageDocument/actionAddEventsDocuments', async (params, thunkAPI) => {
    return await post(EVENT_ADD_DOCUMENTS, params, thunkAPI)
})

//details event document
export const actionDetailsEventsDocuments = createAsyncThunk('appEventImageDocument/actionDetailsEventsDocuments', async (params, thunkAPI) => {
    return await post(EVENT_DETAILS_DOCUMENTS, params, thunkAPI)
})

//Edit event document
export const actionEditEventsDocuments = createAsyncThunk('appEventImageDocument/actionEditEventsDocuments', async (params, thunkAPI) => {
    return await post(EVENT_EDIT_DOCUMENTS, params, thunkAPI)
})

//Delete event document
export const actionDeleteEventsDocuments = createAsyncThunk('appEventImageDocument/actionDeleteEventsDocuments', async (params, thunkAPI) => {
    return await post(EVENT_DELETE_DOCUMENTS, params, thunkAPI)
})

export const appEventImageDocumentSlice = createSlice({
    name: 'appEventImageDocument',
    initialState: {
        //list
        loadingEventImageDocuments: false,
        eventsImageDocuments: null,

        //Add
        loadingAddEventDocuments: false,
        addEventsDocuments: null,

        //Details
        loadingDetailsEventDocuments: false,
        detailsEventsDocuments: null,

        //Edit
        loadingEditEventDocuments: false,
        editEventsDocuments: null,

        //Delete
        loadingDeleteEventDocuments: false,
        deleteEventsDocuments: null
    },
    reducers: {
        //list image doc
        resetEventsImageDocumentsResponse: (state) => {
            state.eventsImageDocuments = null
        },

        //add Event doc
        resetAddEventsDocumentsResponse: (state) => {
            state.addEventsDocuments = null
        },

        //Details Event doc
        resetDetailsEventsDocumentsResponse: (state) => {
            state.detailsEventsDocuments = null
        },

        //Edit Event doc
        resetEditEventsDocumentsResponse: (state) => {
            state.editEventsDocuments = null
        },

        //Delete Event doc
        resetDeleteEventsDocumentsResponse: (state) => {
            state.deleteEventsDocuments = null
        }
    },
    extraReducers: builder => {
        builder
            //list image doc
            .addCase(actionEventsImageDocuments.pending, (state) => {
                state.loadingEventImageDocuments = true
            })
            .addCase(actionEventsImageDocuments.fulfilled, (state, action) => {
                state.loadingEventImageDocuments = false
                state.eventsImageDocuments = action.payload
            })
            .addCase(actionEventsImageDocuments.rejected, (state) => {
                state.loadingEventImageDocuments = false
            })

            //add Event doc
            .addCase(actionAddEventsDocuments.pending, (state) => {
                state.loadingAddEventDocuments = true
            })
            .addCase(actionAddEventsDocuments.fulfilled, (state, action) => {
                state.loadingAddEventDocuments = false
                state.addEventsDocuments = action.payload
            })
            .addCase(actionAddEventsDocuments.rejected, (state) => {
                state.loadingAddEventDocuments = false
            })

            //Details Event doc
            .addCase(actionDetailsEventsDocuments.pending, (state) => {
                state.loadingDetailsEventDocuments = true
            })
            .addCase(actionDetailsEventsDocuments.fulfilled, (state, action) => {
                state.loadingDetailsEventDocuments = false
                state.detailsEventsDocuments = action.payload
            })
            .addCase(actionDetailsEventsDocuments.rejected, (state) => {
                state.loadingDetailsEventDocuments = false
            })

            //Edit Event doc
            .addCase(actionEditEventsDocuments.pending, (state) => {
                state.loadingEditEventDocuments = true
            })
            .addCase(actionEditEventsDocuments.fulfilled, (state, action) => {
                state.loadingEditEventDocuments = false
                state.editEventsDocuments = action.payload
            })
            .addCase(actionEditEventsDocuments.rejected, (state) => {
                state.loadingEditEventDocuments = false
            })

            //Delete Event doc
            .addCase(actionDeleteEventsDocuments.pending, (state) => {
                state.loadingDeleteEventDocuments = true
            })
            .addCase(actionDeleteEventsDocuments.fulfilled, (state, action) => {
                state.loadingDeleteEventDocuments = false
                state.deleteEventsDocuments = action.payload
            })
            .addCase(actionDeleteEventsDocuments.rejected, (state) => {
                state.loadingDeleteEventDocuments = false
            })
    }
})
export const { resetEventsImageDocumentsResponse, resetAddEventsDocumentsResponse, resetDetailsEventsDocumentsResponse, resetEditEventsDocumentsResponse, resetDeleteEventsDocumentsResponse } = appEventImageDocumentSlice.actions

export default appEventImageDocumentSlice.reducer