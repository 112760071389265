// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { ADD_USER, PRE_USER, USER, EDIT_USER, GET_USER, GET_USER_CREATED_BY, PHOTO_VIDEO_USER_LIST, PASSWORD_CHANGE } from '../../common/constant'

export const actionUser = createAsyncThunk('appUser/actionUser', async (thunkAPI) => {
    return await post(USER, thunkAPI)
})

export const actionPreUser = createAsyncThunk('appUser/actionPreUser', async (thunkAPI) => {
    return await post(PRE_USER, thunkAPI)
})

export const actionAddUser = createAsyncThunk('appUser/actionAddser', async (params, thunkAPI) => {
    return await post(ADD_USER, params, thunkAPI)
})

export const actionGetUser = createAsyncThunk('appUser/actionGetUser', async (params, thunkAPI) => {
    return await post(GET_USER, params, thunkAPI)
})

export const actionEditUser = createAsyncThunk('appUser/actionEditUser', async (params, thunkAPI) => {
    return await post(EDIT_USER, params, thunkAPI)
})

export const actionGetCreatedByUser = createAsyncThunk('appUser/actionGetCreatedByUser', async (params, thunkAPI) => {
    return await post(GET_USER_CREATED_BY, params, thunkAPI)
})

export const actionPhotographerVideographerList = createAsyncThunk('appUsersSlice/actionPhotographerVideographerList', async (params, thunkAPI) => {
    return await post(PHOTO_VIDEO_USER_LIST, params, thunkAPI)
})

// change password
export const actionChangePassword = createAsyncThunk('appUser/actionChangePassword', async (params, thunkAPI) => {
    return await post(PASSWORD_CHANGE, params, thunkAPI)
})

export const appUserSlice = createSlice({
    name: 'appUser',
    initialState: {
        //list
        loading: false,
        user: null,

        //Pre User List
        loadingPreUser: false,
        preUser: null,

        //add
        loadingAdd: false,
        addUser: null,

        //get
        loadingGet: false,
        getUser: null,

        //edit
        loadingEdit: false,
        editUser: null,

        //get created By user
        loadingGetCreatedBy: false,
        getUserCreatedBy: null,

        //Photographer videographer list
        loadingPhotographerVideographerList: false,
        photographerVideographerList: null,

        //change password
        loadingChangePassword: false,
        changePassword: null
    },
    reducers: {
        //reset or delete Add User store data
        resetUserResponse: (state) => {
            state.user = null
        },
        resetPreUserResponse: (state) => {
            state.preUser = null
        },
        resetAddUserResponse: (state) => {
            state.addUser = null
        },
        resetGetUserResponse: (state) => {
            state.getUser = null
        },
        resetEditUserResponse: (state) => {
            state.editUser = null
        },
        resetGetCreatedByUserResponse: (state) => {
            state.getUserCreatedBy = null
        },
        resetPhotographerVideographerListResponse: (state) => {
            state.photographerVideographerList = null
        },
        resetChangePasswordResponse: (state) => {
            state.changePassword = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionUser.pending, (state) => {
                state.loading = true
            })
            .addCase(actionUser.fulfilled, (state, action) => {
                state.loading = false
                state.user = action.payload
            })
            .addCase(actionUser.rejected, (state) => {
                state.loading = false
            })

            // Pre User List
            //list
            .addCase(actionPreUser.pending, (state) => {
                state.loading = true
            })
            .addCase(actionPreUser.fulfilled, (state, action) => {
                state.loading = false
                state.preUser = action.payload
            })
            .addCase(actionPreUser.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddUser.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddUser.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addUser = action.payload
            })
            .addCase(actionAddUser.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetUser.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetUser.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getUser = action.payload
            })
            .addCase(actionGetUser.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditUser.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditUser.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editUser = action.payload
            })
            .addCase(actionEditUser.rejected, (state) => {
                state.loadingEdit = false
            })

            //get Created by user
            .addCase(actionGetCreatedByUser.pending, (state) => {
                state.loadingGetCreatedBy = true
            })
            .addCase(actionGetCreatedByUser.fulfilled, (state, action) => {
                state.loadingGetCreatedBy = false
                state.getUserCreatedBy = action.payload
            })
            .addCase(actionGetCreatedByUser.rejected, (state) => {
                state.loadingGetCreatedBy = false
            })

            //Photographer videographer list
            .addCase(actionPhotographerVideographerList.pending, (state) => {
                state.loadingPhotographerVideographerList = true
            })
            .addCase(actionPhotographerVideographerList.fulfilled, (state, action) => {
                state.loadingPhotographerVideographerList = false
                state.photographerVideographerList = action.payload
            })
            .addCase(actionPhotographerVideographerList.rejected, (state) => {
                state.loadingPhotographerVideographerList = false
            })

            //change password
            .addCase(actionChangePassword.pending, (state) => {
                state.loadingChangePassword = true
            })
            .addCase(actionChangePassword.fulfilled, (state, action) => {
                state.loadingChangePassword = false
                state.changePassword = action.payload
            })
            .addCase(actionChangePassword.rejected, (state) => {
                state.loadingChangePassword = false
            })
    }
})
export const { resetUserResponse, resetPreUserResponse, resetAddUserResponse, resetGetUserResponse, resetEditUserResponse, resetGetCreatedByUserResponse, resetPhotographerVideographerListResponse, resetChangePasswordResponse } = appUserSlice.actions

export default appUserSlice.reducer