// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { EVENT_PARTICIPANT_REPORT, EVENT_PARTICIPANT_ROUND_REPORT, EVENT_ROUND_PARTICIPANT_DETAILS_REPORT } from '../../common/constant'

export const actionGetParticipantReport = createAsyncThunk('appParticipantReport/actionGetParticipantReport', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_REPORT, thunkAPI)
})

export const actionGetParticipantRoundReport = createAsyncThunk('appParticipantReport/actionGetParticipantRoundReport', async (params, thunkAPI) => {
    return await post(EVENT_PARTICIPANT_ROUND_REPORT, params, thunkAPI)
})

export const actionGetParticipantDetailsReport = createAsyncThunk('appParticipantReport/actionGetParticipantDetailsReport', async (thunkAPI) => {
    return await post(EVENT_ROUND_PARTICIPANT_DETAILS_REPORT, thunkAPI)
})

export const appParticipantReportSlice = createSlice({
    name: 'appParticipantReport',
    initialState: {
        //list
        loading: false,
        participantReport: null,

        //add
        loadingAdd: false,
        participantRoundReport: null,

        //list
        loadingRoundParticipantsDetails: false,
        roundParticipantsDetailsReport: null

    },
    reducers: {
        //reset or delete Add Country store data
        resetParticipantReportResponse: (state) => {
            state.participantReport = null
        },
        resetParticipantRoundReportResponse: (state) => {
            state.participantRoundReport = null
        },
        resetParticipantRoundDetailsReportResponse: (state) => {
            state.roundParticipantsDetailsReport = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionGetParticipantReport.pending, (state) => {
                state.loading = true
            })
            .addCase(actionGetParticipantReport.fulfilled, (state, action) => {
                state.loading = false
                state.participantReport = action.payload
            })
            .addCase(actionGetParticipantReport.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionGetParticipantRoundReport.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionGetParticipantRoundReport.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.participantRoundReport = action.payload
            })
            .addCase(actionGetParticipantRoundReport.rejected, (state) => {
                state.loadingAdd = false
            })

            // round participants details
            .addCase(actionGetParticipantDetailsReport.pending, (state) => {
                state.loadingRoundParticipantsDetails = true
            })
            .addCase(actionGetParticipantDetailsReport.fulfilled, (state, action) => {
                state.loadingRoundParticipantsDetails = false
                state.roundParticipantsDetailsReport = action.payload
            })
            .addCase(actionGetParticipantDetailsReport.rejected, (state) => {
                state.loadingRoundParticipantsDetails = false
            })
    }
})
export const { resetParticipantReportResponse, resetParticipantRoundReportResponse, resetParticipantRoundDetailsReportResponse } = appParticipantReportSlice.actions

export default appParticipantReportSlice.reducer