// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { ADD_CHANNEL, CHANNEL, EDIT_CHANNEL, GET_CHANNEL, DELETE_CHANNEL, ADD_LINK, LIST_LINK, EDIT_LINK, GET_LINK, DELETE_LINK } from '../../common/constant'

export const actionChannelList = createAsyncThunk('appVideo/actionChannelList', async (thunkAPI) => {
    return await post(CHANNEL, thunkAPI)
})

export const actionAddChannel = createAsyncThunk('appVideo/actionAddChannel', async (params, thunkAPI) => {
    return await put(ADD_CHANNEL, params, thunkAPI)
})
export const actionGetChannel = createAsyncThunk('appVideo/actionGetChannel', async (params, thunkAPI) => {
    return await post(GET_CHANNEL, params, thunkAPI)
})
export const actionEditChannel = createAsyncThunk('appVideo/actionEditChannel', async (params, thunkAPI) => {
    return await post(EDIT_CHANNEL, params, thunkAPI)
})

export const actionDeleteVideo = createAsyncThunk('appVideo/actionDeleteVideo', async (params, thunkAPI) => {
    return await post(DELETE_CHANNEL, params, thunkAPI)
})

export const actionLinkList = createAsyncThunk('appVideo/actionLinkList', async (thunkAPI) => {
    return await post(LIST_LINK, thunkAPI)
})

export const actionAddLink = createAsyncThunk('appVideo/actionAddLink', async (params, thunkAPI) => {
    return await put(ADD_LINK, params, thunkAPI)
})
export const actionGetLink = createAsyncThunk('appVideo/actionGetLink', async (params, thunkAPI) => {
    return await post(GET_LINK, params, thunkAPI)
})
export const actionEditLink = createAsyncThunk('appVideo/actionEditLink', async (params, thunkAPI) => {
    return await post(EDIT_LINK, params, thunkAPI)
})

export const actionDeleteLink = createAsyncThunk('appVideo/actionDeleteLink', async (params, thunkAPI) => {
    return await post(DELETE_LINK, params, thunkAPI)
})

export const appVideoSlice = createSlice({
    name: 'appVideo',
    initialState: {
        //list
        loading: false,
        channelList: null,

        //add
        loadingAdd: false,
        addChannel: null,

        //Get
        loadingGet: false,
        getChannel: null,

        //edit
        loadingEdit: false,
        editChannel: null,

        //delete
        loadingDelete: false,
        deleteChannel: null,

        //link list
        loadingLink: false,
        linkList: null,

        //add link
        loadingAddChannel: false,
        addLink: null,

        //Get link
        loadingGetChannel: false,
        getLink: null,

        //Edit link
        loadingEditChannel: false,
        editLink: null,

        //delete link
        loadingChannelDelete: false,
        deleteLink: null

    },
    reducers: {
        //reset or delete Add Country store data
        resetVideoListResponse: (state) => {
            state.channelList = null
        },
        resetAddVideoResponse: (state) => {
            state.addChannel = null
        },
        resetGetVideoResponse: (state) => {
            state.getChannel = null
        },
        resetEditVideoResponse: (state) => {
            state.editChannel = null
        },
        resetDeleteVideoResponse: (state) => {
            state.deleteChannel = null
        },
        resetChannelListResponse: (state) => {
            state.linkList = null
        },
        resetAddChannelResponse: (state) => {
            state.addLink = null
        },
        resetGetChannelResponse: (state) => {
            state.getLink = null
        },
        resetEditChannelResponse: (state) => {
            state.editLink = null
        },
        resetDeleteChannelResponse: (state) => {
            state.deleteLink = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionChannelList.pending, (state) => {
                state.loading = true
            })
            .addCase(actionChannelList.fulfilled, (state, action) => {
                state.loading = false
                state.channelList = action.payload
            })
            .addCase(actionChannelList.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddChannel.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddChannel.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addChannel = action.payload
            })
            .addCase(actionAddChannel.rejected, (state) => {
                state.loadingAdd = false
            })

            //Get
            .addCase(actionGetChannel.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetChannel.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getChannel = action.payload
            })
            .addCase(actionGetChannel.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditChannel.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditChannel.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editChannel = action.payload
            })
            .addCase(actionEditChannel.rejected, (state) => {
                state.loadingEdit = false
            })
            //delete
            .addCase(actionDeleteVideo.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteVideo.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteChannel = action.payload
            })
            .addCase(actionDeleteVideo.rejected, (state) => {
                state.loadingDelete = false
            })

            //Add Channel
            .addCase(actionAddLink.pending, (state) => {
                state.loadingAddChannel = true
            })
            .addCase(actionAddLink.fulfilled, (state, action) => {
                state.loadingAddChannel = false
                state.addLink = action.payload
            })
            .addCase(actionAddLink.rejected, (state) => {
                state.loadingEdit = false
            })

            //channel list
            .addCase(actionLinkList.pending, (state) => {
                state.loadingLink = true
            })
            .addCase(actionLinkList.fulfilled, (state, action) => {
                state.loadingLink = false
                state.linkList = action.payload
            })
            .addCase(actionLinkList.rejected, (state) => {
                state.loadingLink = false
            })
            //get
            .addCase(actionGetLink.pending, (state) => {
                state.loadingGetChannel = true
            })
            .addCase(actionGetLink.fulfilled, (state, action) => {
                state.loadingGetChannel = false
                state.getLink = action.payload
            })
            .addCase(actionGetLink.rejected, (state) => {
                state.loadingGetChannel = false
            })
            //edit
            .addCase(actionEditLink.pending, (state) => {
                state.loadingEditChannel = true
            })
            .addCase(actionEditLink.fulfilled, (state, action) => {
                state.loadingEditChannel = false
                state.editLink = action.payload
            })
            .addCase(actionEditLink.rejected, (state) => {
                state.loadingEditChannel = false
            })
            //delete channel
            .addCase(actionDeleteLink.pending, (state) => {
                state.loadingChannelDelete = true
            })
            .addCase(actionDeleteLink.fulfilled, (state, action) => {
                state.loadingChannelDelete = false
                state.deleteLink = action.payload
            })
            .addCase(actionDeleteLink.rejected, (state) => {
                state.loadingChannelDelete = false
            })


    }
})
export const { resetVideoListResponse,
    resetAddVideoResponse,
    resetGetVideoResponse,
    resetAddChannelResponse,
    resetEditVideoResponse,
    resetChannelListResponse,
    resetGetChannelResponse,
    resetEditChannelResponse,
    resetDeleteVideoResponse,
    resetDeleteChannelResponse
} = appVideoSlice.actions

export default appVideoSlice.reducer