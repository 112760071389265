// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { DASHBOARD_COUNT, DASHBOARD_PROFILE_WISE_USER, DASHBOARD_TODAYS_EVENT_LIST, DASHBOARD_TOTAL_POST_LIST, DASHBOARD_TOTAL_POST_TYPE, DASHBOARD_TOTAL_RACE_LIST, DASHBOARD_TOTAL_USER_LIST, FEEDBACK_LIST } from '../../common/constant'

export const actionDashboardCount = createAsyncThunk('appDashboard/actionDashboardCount', async (params, thunkAPI) => {
    return await post(DASHBOARD_COUNT, params, thunkAPI)
})

export const actionDashboardTodaysEventList = createAsyncThunk('appDashboard/actionDashboardTodaysEventList', async (params, thunkAPI) => {
    return await post(DASHBOARD_TODAYS_EVENT_LIST, params, thunkAPI)
})

export const actionDashboardTotalUserList = createAsyncThunk('appDashboard/actionDashboardTotalUserList', async (params, thunkAPI) => {
    return await post(DASHBOARD_TOTAL_USER_LIST, params, thunkAPI)
})

export const actionDashboardTotalPostList = createAsyncThunk('appDashboard/actionDashboardTotalPostList', async (params, thunkAPI) => {
    return await post(DASHBOARD_TOTAL_POST_LIST, params, thunkAPI)
})

export const actionDashboardTotalRaceList = createAsyncThunk('appDashboard/actionDashboardTotalRaceList', async (params, thunkAPI) => {
    return await post(DASHBOARD_TOTAL_RACE_LIST, params, thunkAPI)
})

export const actionFeedbackList = createAsyncThunk('appDashboard/actionFeedbackList', async (params, thunkAPI) => {
    return await post(FEEDBACK_LIST, params, thunkAPI)
})

export const actionProfileWiseUser = createAsyncThunk('appDashboard/actionProfileWiseUser', async (params, thunkAPI) => {
    return await post(DASHBOARD_PROFILE_WISE_USER, params, thunkAPI)
})

//post type api
export const actionTotalPostType = createAsyncThunk('appDashboard/actionTotalPostType', async (params, thunkAPI) => {
    return await post(DASHBOARD_TOTAL_POST_TYPE, params, thunkAPI)
})

export const appDashboardSLice = createSlice({
    name: 'appDashboard',
    initialState: {
        //Dashboard Count
        loadingDashboardCount: false,
        dashboardCount: null,

        //Dashboard Todays Event List
        loadingDashboardTodaysEventList: false,
        dashboardTodaysEventList: null,

        //Dashboard Total User List
        loadingDashboardTotalUserList: false,
        dashboardTotalUserList: null,

        //Dashboard Post User List
        loadingDashboardTotalPostList: false,
        dashboardTotalPostList: null,

        //Dashboard race User List
        loadingDashboardTotalRaceList: false,
        dashboardTotalRaceList: null,

        ////Dashboard race User List
        loadingFeedBack: false,
        getFeedback: null,

        //profile wise user
        loadingProfileWiseUser: false,
        profileWiseUser: null,

        //Post type total
        loadingTotalPostType: false,
        totalPostType: null
    },
    reducers: {
        // Dashboard response
        resetDashboardCountResponse: (state) => {
            state.dashboardCount = null
        },
        //reset Dashboard Todays Event List Response
        resetDashboardTodaysEventListResponse: (state) => {
            state.dashboardTodaysEventList = null
        },
        //reset Dashboard Total User List Response
        resetDashboardTotalUserListResponse: (state) => {
            state.dashboardTotalUserList = null
        },
        //reset Dashboard Total Post List Response
        resetDashboardTotalPostListResponse: (state) => {
            state.dashboardTotalPostList = null
        },
        //reset Dashboard Total race List Response
        resetDashboardTotalRaceListResponse: (state) => {
            state.dashboardTotalRaceList = null
        },
        resetFeedbackResponse: (state) => {
            state.getFeedback = null
        },
        //profile wise user
        resetProfileWiseUserResponse: (state) => {
            state.profileWiseUser = null
        },
        //Total Post
        resetTotalPostTypeResponse: (state) => {
            state.totalPostType = null
        }
    },
    extraReducers: builder => {
        builder
            //Dashboard Count
            .addCase(actionDashboardCount.pending, (state) => {
                state.loadingDashboardCount = true
            })
            .addCase(actionDashboardCount.fulfilled, (state, action) => {
                state.loadingDashboardCount = false
                state.dashboardCount = action.payload
            })
            .addCase(actionDashboardCount.rejected, (state) => {
                state.loadingDashboardCount = false
            })

            //todays event list
            .addCase(actionDashboardTodaysEventList.pending, (state) => {
                state.loadingDashboardTodaysEventList = true
            })
            .addCase(actionDashboardTodaysEventList.fulfilled, (state, action) => {
                state.loadingDashboardTodaysEventList = false
                state.dashboardTodaysEventList = action.payload
            })
            .addCase(actionDashboardTodaysEventList.rejected, (state) => {
                state.loadingDashboardTodaysEventList = false
            })

            //Total User list
            .addCase(actionDashboardTotalUserList.pending, (state) => {
                state.loadingDashboardTotalUserList = true
            })
            .addCase(actionDashboardTotalUserList.fulfilled, (state, action) => {
                state.loadingDashboardTotalUserList = false
                state.dashboardTotalUserList = action.payload
            })
            .addCase(actionDashboardTotalUserList.rejected, (state) => {
                state.loadingDashboardTotalUserList = false
            })

            //Total Post list
            .addCase(actionDashboardTotalPostList.pending, (state) => {
                state.loadingDashboardTotalPostList = true
            })
            .addCase(actionDashboardTotalPostList.fulfilled, (state, action) => {
                state.loadingDashboardTotalPostList = false
                state.dashboardTotalPostList = action.payload
            })
            .addCase(actionDashboardTotalPostList.rejected, (state) => {
                state.loadingDashboardTotalPostList = false
            })

            //Total Race list
            .addCase(actionDashboardTotalRaceList.pending, (state) => {
                state.loadingDashboardTotalRaceList = true
            })
            .addCase(actionDashboardTotalRaceList.fulfilled, (state, action) => {
                state.loadingDashboardTotalRaceList = false
                state.dashboardTotalRaceList = action.payload
            })
            .addCase(actionDashboardTotalRaceList.rejected, (state) => {
                state.loadingDashboardTotalRaceList = false
            })

            //Get Feedback
            .addCase(actionFeedbackList.pending, (state) => {
                state.loadingFeedBack = true
            })
            .addCase(actionFeedbackList.fulfilled, (state, action) => {
                state.loadingFeedBack = false
                state.getFeedback = action.payload
            })
            .addCase(actionFeedbackList.rejected, (state) => {
                state.loadingFeedBack = false
            })

            //Profile Wise User
            .addCase(actionProfileWiseUser.pending, (state) => {
                state.loadingProfileWiseUser = true
            })
            .addCase(actionProfileWiseUser.fulfilled, (state, action) => {
                state.loadingProfileWiseUser = false
                state.profileWiseUser = action.payload
            })
            .addCase(actionProfileWiseUser.rejected, (state) => {
                state.loadingProfileWiseUser = false
            })

            //post type 
            .addCase(actionTotalPostType.pending, (state) => {
                state.loadingTotalPostType = true
            })
            .addCase(actionTotalPostType.fulfilled, (state, action) => {
                state.loadingTotalPostType = false
                state.totalPostType = action.payload
            })
            .addCase(actionTotalPostType.rejected, (state) => {
                state.loadingTotalPostType = false
            })
    }
})
export const { resetDashboardCountResponse, resetDashboardTodaysEventListResponse, resetDashboardTotalUserListResponse, resetDashboardTotalPostListResponse, resetDashboardTotalRaceListResponse, resetFeedbackResponse, resetProfileWiseUserResponse,
               resetTotalPostTypeResponse } = appDashboardSLice.actions

export default appDashboardSLice.reducer