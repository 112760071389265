import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from "../../common/api"
import {
    EVENT_ORGANIZER,
    EVENT_ORGANIZER_ADD,
    EVENT_ORGANIZER_EDIT,
    EVENT_ORGANIZER_DETAILS,
    EVENT_ORGANIZER_DELETE,
    EVENT_ORGANIZER_SEARCH
} from '../../common/constant'

export const actionEventOrganizer = createAsyncThunk('appEventOrganizer/actionEventOrganizer', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER, thunkAPI)
})

export const actionEventOrganizerAdd = createAsyncThunk('appEventOrganizer/actionEventOrganizerAdd', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER_ADD, thunkAPI)
})

export const actionEventOrganizerEdit = createAsyncThunk('appEventOrganizer/actionEventOrganizerEdit', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER_EDIT, thunkAPI)
})

export const actionEventOrganizeDetails = createAsyncThunk('appEventOrganizer/actionEventOrganizeDetails', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER_DETAILS, thunkAPI)
})

export const actionEventOrganizeDelete = createAsyncThunk('appEventOrganizer/actionEventOrganizeDelete', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER_DELETE, thunkAPI)
})

export const actionEventOrganizeSearch = createAsyncThunk('appEventOrganizer/actionEventOrganizeSearch', async (thunkAPI) => {
    return await post(EVENT_ORGANIZER_SEARCH, thunkAPI)
})

export const appEventOrganizerSlice = createSlice({
    name: 'appEventOrganizer',
    initialState: {
        //event organizer
        loadingEventOrganizers: false,
        eventOrganizers: null,

        //event organizer add
        loadingEventOrganizerAdd: false,
        eventOrganizerAdd: null,

        //event organizer edit
        loadingEventOrganizerEdit: false,
        eventOrganizerEdit: null,

        //event organizer details
        loadingEventOrganizerDetails: false,
        eventOrganizerDetails: null,

        //event organizer Delete
        loadingEventOrganizerDelete: false,
        eventOrganizerDelete: null,

        //event organizer Delete
        loadingEventOrganizerSearch: false,
        eventOrganizerSearch: null
    },
    reducers: {
        resetEventOrganizersResponse: (state) => {
            state.eventOrganizers = null
        },
        resetEventOrganizerAddResponse: (state) => {
            state.eventOrganizerAdd = null
        },
        resetEventOrganizerEditResponse: (state) => {
            state.eventOrganizerEdit = null
        },
        resetEventOrganizerDetailsResponse: (state) => {
            state.eventOrganizerDetails = null
        },
        resetEventOrganizerDeleteResponse: (state) => {
            state.eventOrganizerDelete = null
        },
        resetEventOrganizerSearchResponse: (state) => {
            state.eventOrganizerSearch = null
        }
    },
    extraReducers: builder => {
        builder
            //event organizers
            .addCase(actionEventOrganizer.pending, (state) => {
                state.loadingEventOrganizers = true
            })
            .addCase(actionEventOrganizer.fulfilled, (state, action) => {
                state.loadingEventOrganizers = false
                state.eventOrganizers = action.payload
            })
            .addCase(actionEventOrganizer.rejected, (state) => {
                state.loadingEventOrganizers = false
            })

            //event organizer add
            .addCase(actionEventOrganizerAdd.pending, (state) => {
                state.loadingEventOrganizerAdd = true
            })
            .addCase(actionEventOrganizerAdd.fulfilled, (state, action) => {
                state.loadingEventOrganizerAdd = false
                state.eventOrganizerAdd = action.payload
            })
            .addCase(actionEventOrganizerAdd.rejected, (state) => {
                state.loadingEventOrganizerAdd = false
            })

            //event organizer edit
            .addCase(actionEventOrganizerEdit.pending, (state) => {
                state.loadingEventOrganizerEdit = true
            })
            .addCase(actionEventOrganizerEdit.fulfilled, (state, action) => {
                state.loadingEventOrganizerEdit = false
                state.eventOrganizerEdit = action.payload
            })
            .addCase(actionEventOrganizerEdit.rejected, (state) => {
                state.loadingEventOrganizerEdit = false
            })

            //event organizer Details
            .addCase(actionEventOrganizeDetails.pending, (state) => {
                state.loadingEventOrganizerDetails = true
            })
            .addCase(actionEventOrganizeDetails.fulfilled, (state, action) => {
                state.loadingEventOrganizerDetails = false
                state.eventOrganizerDetails = action.payload
            })
            .addCase(actionEventOrganizeDetails.rejected, (state) => {
                state.loadingEventOrganizerDetails = false
            })

            //event organizer Delete
            .addCase(actionEventOrganizeDelete.pending, (state) => {
                state.loadingEventOrganizerDelete = true
            })
            .addCase(actionEventOrganizeDelete.fulfilled, (state, action) => {
                state.loadingEventOrganizerDelete = false
                state.eventOrganizerDelete = action.payload
            })
            .addCase(actionEventOrganizeDelete.rejected, (state) => {
                state.loadingEventOrganizerDelete = false
            })


            //event organizer Delete
            .addCase(actionEventOrganizeSearch.pending, (state) => {
                state.loadingEventOrganizerSearch = true
            })
            .addCase(actionEventOrganizeSearch.fulfilled, (state, action) => {
                state.loadingEventOrganizerSearch = false
                state.eventOrganizerSearch = action.payload
            })
            .addCase(actionEventOrganizeSearch.rejected, (state) => {
                state.loadingEventOrganizerSearch = false
            })
    }
})
export const {
    resetEventOrganizersResponse,
    resetEventOrganizerAddResponse,
    resetEventOrganizerEditResponse,
    resetEventOrganizerDetailsResponse,
    resetEventOrganizerDeleteResponse,
    resetEventOrganizerSearchResponse
} = appEventOrganizerSlice.actions

export default appEventOrganizerSlice.reducer