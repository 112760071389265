import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DIRECTORY_LIST } from '../../common/constant'
import { post } from '../../common/api'

//List
export const actionDirectoryList = createAsyncThunk('appDirectory/actionDirectoryList', async (params, thunkAPI) => {
    return await post(DIRECTORY_LIST, params, thunkAPI)
})

export const directorySlice = createSlice({
    name: 'appDirectory',
    initialState: {
        //list
        loadingDirectoryList: false,
        directoryList: null

    },
    reducers: {
        // List store
        resetDirectoryListResponse: (state) => {
            state.directoryList = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionDirectoryList.pending, (state) => {
                state.loadingDirectoryList = true
            })
            .addCase(actionDirectoryList.fulfilled, (state, action) => {
                state.loadingDirectoryList = false
                state.directoryList = action.payload
            })
            .addCase(actionDirectoryList.rejected, (state) => {
                state.loadingDirectoryList = false
            })   
    }
})
export const { resetDirectoryListResponse } = directorySlice.actions

export default directorySlice.reducer