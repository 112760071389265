// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put, get } from '../../common/api'
import { ADD_VILLAGE, EDIT_VILLAGE, GET_VILLAGE, VILLAGE, DELETE_VILLAGE, NEW_VILLAGE } from '../../common/constant'

export const actionVillage = createAsyncThunk('appVillage/actionVillage', async (params, thunkAPI) => {
    return await post(VILLAGE, params, thunkAPI)
})

export const actionAddVillage = createAsyncThunk('appVillage/actionAddVillage', async (params, thunkAPI) => {
    return await put(ADD_VILLAGE, params, thunkAPI)
})

export const actionGetVillage = createAsyncThunk('appVillage/actionGetVillage', async (params, thunkAPI) => {
    return await post(GET_VILLAGE, params, thunkAPI)
})

export const actionEditVillage = createAsyncThunk('appVillage/actionEditVillage', async (params, thunkAPI) => {
    return await post(EDIT_VILLAGE, params, thunkAPI)
})

export const actionDeleteVillage = createAsyncThunk('appVillage/actionDeleteVillage', async (params, thunkAPI) => {
    return await post(DELETE_VILLAGE, params, thunkAPI)
})

export const actionNewVillage = createAsyncThunk('appVillage/actionNewVillage', async (params, thunkAPI) => {
    return await get(NEW_VILLAGE, params, thunkAPI)
})

export const appVillageSlice = createSlice({
    name: 'appVillage',
    initialState: {
        //list
        loading: false,
        village: null,

        //add
        loadingAdd: false,
        addVillage: null,

        //get
        loadingGet: false,
        getVillage: null,

        //edit
        loadingEdit: false,
        editVillage: null,

        //delete
        loadingDelete: false,
        deleteVillage: null,

        //delete
        loadingNewVillage: false,
        newVillage: null

    },
    reducers: {
        //reset or delete Add Village store data
        resetVillageResponse: (state) => {
            state.village = null
        },
        resetAddVillageResponse: (state) => {
            state.addVillage = null
        },
        resetGetVillageResponse: (state) => {
            state.getVillage = null
        },
        resetEditVillageResponse: (state) => {
            state.editVillage = null
        },
        resetDeleteVillageResponse: (state) => {
            state.deleteVillage = null
        },
        resetNewVillageResponse: (state) => {
            state.newVillage = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionVillage.pending, (state) => {
                state.loading = true
            })
            .addCase(actionVillage.fulfilled, (state, action) => {
                state.loading = false
                state.village = action.payload
            })
            .addCase(actionVillage.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddVillage.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddVillage.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addVillage = action.payload
            })
            .addCase(actionAddVillage.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetVillage.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetVillage.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getVillage = action.payload
            })
            .addCase(actionGetVillage.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditVillage.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditVillage.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editVillage = action.payload
            })
            .addCase(actionEditVillage.rejected, (state) => {
                state.loadingEdit = false
            })

            //delete
            .addCase(actionDeleteVillage.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteVillage.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteVillage = action.payload
            })
            .addCase(actionDeleteVillage.rejected, (state) => {
                state.loadingDelete = false
            })

            //new village / missing village
            .addCase(actionNewVillage.pending, (state) => {
                state.loadingNewVillage = true
            })
            .addCase(actionNewVillage.fulfilled, (state, action) => {
                state.loadingNewVillage = false
                state.newVillage = action.payload
            })
            .addCase(actionNewVillage.rejected, (state) => {
                state.loadingNewVillage = false
            })
    }
})
export const { resetVillageResponse, resetAddVillageResponse, resetGetVillageResponse, resetEditVillageResponse, resetDeleteVillageResponse, resetNewVillageResponse } = appVillageSlice.actions

export default appVillageSlice.reducer