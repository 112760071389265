import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

//** import apis

export const actionNavSelected = createAsyncThunk('user/actionNavSelected', (params) => {
    return params
})

export const actionUserAddNavSelected = createAsyncThunk('user/actionUserAddNavSelected', (params) => {
    return params
})

export const actionEventNavSelected = createAsyncThunk('user/actionEventNavSelected', (params) => {
    return params
})

export const userNavSlice = createSlice({
    name: 'userNavbar',
    initialState: {
        //user Nav Selection
        userNavSelection: false,
        userNavSelected: null,

        //user Add Nav Selection
        userAddNavSelection: false,
        userAddNavSelected: null,

        //user Add Nav Selection
        userEventNavSelection: false,
        userEventNavSelected: null

    },
    reducers: {
        //reset or delete Add store data
        resetUserNavSelection: (state) => {
            state.userNavSelected = null
        },
        resetUserAddNavSelection: (state) => {
            state.userAddNavSelected = null
        },
        resetEventNavSelection: (state) => {
            state.userEventNavSelected = null
        }
    },
    extraReducers: builder => {
        builder
            //user Nav Selection
            .addCase(actionNavSelected.pending, (state) => {
                state.userNavSelection = true
            })
            .addCase(actionNavSelected.fulfilled, (state, action) => {
                state.userNavSelection = false
                state.userNavSelected = action.payload
            })
            .addCase(actionNavSelected.rejected, (state) => {
                state.userNavSelection = false
            })

            //user Add Nav Selection
            .addCase(actionUserAddNavSelected.pending, (state) => {
                state.userAddNavSelection = true
            })
            .addCase(actionUserAddNavSelected.fulfilled, (state, action) => {
                state.userAddNavSelection = false
                state.userAddNavSelected = action.payload
            })
            .addCase(actionUserAddNavSelected.rejected, (state) => {
                state.userAddNavSelection = false
            })

            //EVENT ROUND Add Nav Selection
            .addCase(actionEventNavSelected.pending, (state) => {
                state.userEventNavSelection = true
            })
            .addCase(actionEventNavSelected.fulfilled, (state, action) => {
                state.userEventNavSelection = false
                state.userEventNavSelected = action.payload
            })
            .addCase(actionEventNavSelected.rejected, (state) => {
                state.userEventNavSelection = false
            })

    }
})
export const {
    resetUserNavSelection,
    resetUserAddNavSelection
} = userNavSlice.actions

export default userNavSlice.reducer