import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { SETTING_ADD, SETTING_DETAILS, SETTING_EDIT, SETTING_LIST } from '../../common/constant'

//** import apis

//List
export const actionSettingMasterList = createAsyncThunk('appSettingMaster/actionSettingMasterList', async (params, thunkAPI) => {
    return await post(SETTING_LIST, params, thunkAPI)
})

//add
export const actionSettingMasterAdd = createAsyncThunk('appSettingMaster/actionSettingMasterAdd', async (params, thunkAPI) => {
    return await post(SETTING_ADD, params, thunkAPI)
})

//detail
export const actionSettingMasterDetail = createAsyncThunk('appSettingMaster/actionSettingMasterDetail', async (params, thunkAPI) => {
    return await post(SETTING_DETAILS, params, thunkAPI)
})

//Edit
export const actionSettingMasterEdit = createAsyncThunk('appSettingMaster/actionSettingMasterEdit', async (params, thunkAPI) => {
    return await post(SETTING_EDIT, params, thunkAPI)
})

export const settingMasterSlice = createSlice({
    name: 'appSettingMaster',
    initialState: {
        //list
        loadingSettingMasterList: false,
        settingMasterList: null,

        //add
        loadingSettingMasterAdd: false,
        settingMasterAdd: null,

        //details
        loadingSettingMasterDetail: false,
        settingMasterDetail: null,

        //Edit
        loadingSettingMasterEdit: false,
        settingMasterEdit: null

    },
    reducers: {
        // List store
        resetSettingMasterListResponse: (state) => {
            state.settingMasterList = null
        },

        // Add store
        resetSettingMasterAddResponse: (state) => {
            state.settingMasterAdd = null
        },

        // Detail store
        resetSettingMasterDetailResponse: (state) => {
            state.settingMasterDetail = null
        },

        // Edit store
        resetSettingMasterEditResponse: (state) => {
            state.settingMasterEdit = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionSettingMasterList.pending, (state) => {
                state.loadingSettingMasterList = true
            })
            .addCase(actionSettingMasterList.fulfilled, (state, action) => {
                state.loadingSettingMasterList = false
                state.settingMasterList = action.payload
            })
            .addCase(actionSettingMasterList.rejected, (state) => {
                state.loadingSettingMasterList = false
            })     
            
            //add
            .addCase(actionSettingMasterAdd.pending, (state) => {
                state.loadingSettingMasterAdd = true
            })
            .addCase(actionSettingMasterAdd.fulfilled, (state, action) => {
                state.loadingSettingMasterAdd = false
                state.settingMasterAdd = action.payload
            })
            .addCase(actionSettingMasterAdd.rejected, (state) => {
                state.loadingSettingMasterAdd = false
            })

            //details
            .addCase(actionSettingMasterDetail.pending, (state) => {
                state.loadingSettingMasterDetail = true
            })
            .addCase(actionSettingMasterDetail.fulfilled, (state, action) => {
                state.loadingSettingMasterDetail = false
                state.settingMasterDetail = action.payload
            })
            .addCase(actionSettingMasterDetail.rejected, (state) => {
                state.loadingSettingMasterDetail = false
            })

            //Edit
            .addCase(actionSettingMasterEdit.pending, (state) => {
                state.loadingSettingMasterEdit = true
            })
            .addCase(actionSettingMasterEdit.fulfilled, (state, action) => {
                state.loadingSettingMasterEdit = false
                state.settingMasterEdit = action.payload
            })
            .addCase(actionSettingMasterEdit.rejected, (state) => {
                state.loadingSettingMasterEdit = false
            })
    }
})
export const { resetSettingMasterListResponse, resetSettingMasterAddResponse, resetSettingMasterDetailResponse, resetSettingMasterEditResponse } = settingMasterSlice.actions

export default settingMasterSlice.reducer