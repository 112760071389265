// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { POST_LIST, POST_ADD, POST_EDIT, POST_DETAILS, POST_DELETE, POST_GET_LIKE, POST_GET_COMMENT, POST_GET_REPORT, POST_COMMENT_REMOVE, POST_REMOVE, POST_REPORT_COUNT, BOOST_POST_LIST } from '../../common/constant'

export const actionPost = createAsyncThunk('appPost/actionPost', async (params, thunkAPI) => {
    return await post(POST_LIST, params, thunkAPI)
})

export const actionAddPost = createAsyncThunk('appPost/actionAddPost', async (params, thunkAPI) => {
    return await post(POST_ADD, params, thunkAPI)
})

export const actionPostDetails = createAsyncThunk('appPost/actionPostDetails', async (params, thunkAPI) => {
    return await post(POST_DETAILS, params, thunkAPI)
})

export const actionPostEdit = createAsyncThunk('appPost/actionPostEdit', async (params, thunkAPI) => {
    return await post(POST_EDIT, params, thunkAPI)
})

export const actionPostDelete = createAsyncThunk('appPost/actionPostDelete', async (params, thunkAPI) => {
    return await post(POST_DELETE, params, thunkAPI)
})

export const actionGetLikeList = createAsyncThunk('appPost/actionGetLikeList', async (params, thunkAPI) => {
    return await post(POST_GET_LIKE, params, thunkAPI)
})

export const actionGetCommentList = createAsyncThunk('appPost/actionGetCommentList', async (params, thunkAPI) => {
    return await post(POST_GET_COMMENT, params, thunkAPI)
})

export const actionGetReportList = createAsyncThunk('appPost/actionGetReportList', async (params, thunkAPI) => {
    return await post(POST_GET_REPORT, params, thunkAPI)
})

export const actionPostCommentRemove = createAsyncThunk('appPost/actionPostCommentRemove', async (params, thunkAPI) => {
    return await post(POST_COMMENT_REMOVE, params, thunkAPI)
})

export const actionPostRemove = createAsyncThunk('appPost/actionPostRemove', async (params, thunkAPI) => {
    return await post(POST_REMOVE, params, thunkAPI)
})

export const actionPostReportCount = createAsyncThunk('appPost/actionPostReportCount', async (params, thunkAPI) => {
    return await post(POST_REPORT_COUNT, params, thunkAPI)
})

export const actionBoostPostList = createAsyncThunk('appPost/actionBoostPostList', async (params, thunkAPI) => {
    return await post(BOOST_POST_LIST, params, thunkAPI)
})

export const appPostSlice = createSlice({
    name: 'appPost',
    initialState: {
        //list
        loading: false,
        post: null,

        //add
        loadingAdd: false,
        addPost: null,

        //get
        loadingGet: false,
        postDetails: null,

        //edit
        loadingEdit: false,
        editPost: null,

        //delete
        loadingDelete: false,
        deletePost: null,

        //Like list
        loadingLikes: false,
        getLikes: null,

        //Like Comment
        loadingComments: false,
        getComments: null,

        //Post report
        loadingReports: false,
        getReports: null,

        //Post Comment Remove
        loadingPostCommentRemove: false,
        postCommentRemove: null,

        //Post Remove
        loadingPostRemove: false,
        postRemove: null,

        //Post Report List
        loadingPostReportCount: false,
        postRemoveCount: null,

        //Boost Post Report List
        loadingBoostPostList: false,
        boostPostList: null

    },
    reducers: {
        //reset or delete Add Insurance State store data
        resetPostResponse: (state) => {
            state.post = null
        },
        resetAddPostResponse: (state) => {
            state.addPost = null
        },
        resetPostDetailsResponse: (state) => {
            state.postDetails = null
        },
        resetEditPostResponse: (state) => {
            state.editPost = null
        },
        resetDeletePostResponse: (state) => {
            state.deletePost = null
        },
        resetGetLikesResponse: (state) => {
            state.getLikes = null
        },
        resetGetCommentsResponse: (state) => {
            state.getComments = null
        },
        resetGetReportResponse: (state) => {
            state.getReports = null
        },
        resetPostCommentRemoveResponse: (state) => {
            state.postCommentRemove = null
        },
        resetPostRemoveResponse: (state) => {
            state.postRemove = null
        },
        resetPostReportCountResponse: (state) => {
            state.postRemoveCount = null
        },
        resetBoostPostListResponse: (state) => {
            state.boostPostList = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionPost.pending, (state) => {
                state.loading = true
            })
            .addCase(actionPost.fulfilled, (state, action) => {
                state.loading = false
                state.post = action.payload
            })
            .addCase(actionPost.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddPost.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddPost.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addPost = action.payload
            })
            .addCase(actionAddPost.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionPostDetails.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionPostDetails.fulfilled, (state, action) => {
                state.loadingGet = false
                state.postDetails = action.payload
            })
            .addCase(actionPostDetails.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionPostEdit.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionPostEdit.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editPost = action.payload
            })
            .addCase(actionPostEdit.rejected, (state) => {
                state.loadingEdit = false
            })

            //delete
            .addCase(actionPostDelete.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionPostDelete.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deletePost = action.payload
            })
            .addCase(actionPostDelete.rejected, (state) => {
                state.loadingDelete = false
            })

            //Get Like
            .addCase(actionGetLikeList.pending, (state) => {
                state.loadingLikes = true
            })
            .addCase(actionGetLikeList.fulfilled, (state, action) => {
                state.loadingLikes = false
                state.getLikes = action.payload
            })
            .addCase(actionGetLikeList.rejected, (state) => {
                state.loadingLikes = false
            })

            //Get Comments
            .addCase(actionGetCommentList.pending, (state) => {
                state.loadingComments = true
            })
            .addCase(actionGetCommentList.fulfilled, (state, action) => {
                state.loadingComments = false
                state.getComments = action.payload
            })
            .addCase(actionGetCommentList.rejected, (state) => {
                state.loadingComments = false
            })

            //Get Post Report 
            .addCase(actionGetReportList.pending, (state) => {
                state.loadingReports = true
            })
            .addCase(actionGetReportList.fulfilled, (state, action) => {
                state.loadingReports = false
                state.getReports = action.payload
            })
            .addCase(actionGetReportList.rejected, (state) => {
                state.loadingReports = false
            })

            //Post Comment Remove
            .addCase(actionPostCommentRemove.pending, (state) => {
                state.loadingPostCommentRemove = true
            })
            .addCase(actionPostCommentRemove.fulfilled, (state, action) => {
                state.loadingPostCommentRemove = false
                state.postCommentRemove = action.payload
            })
            .addCase(actionPostCommentRemove.rejected, (state) => {
                state.loadingPostCommentRemove = false
            })

            //Post Remove
            .addCase(actionPostRemove.pending, (state) => {
                state.loadingPostRemove = true
            })
            .addCase(actionPostRemove.fulfilled, (state, action) => {
                state.loadingPostRemove = false
                state.postRemove = action.payload
            })
            .addCase(actionPostRemove.rejected, (state) => {
                state.loadingPostRemove = false
            })

            //Post Report List
            .addCase(actionPostReportCount.pending, (state) => {
                state.loadingPostReportCount = true
            })
            .addCase(actionPostReportCount.fulfilled, (state, action) => {
                state.loadingPostReportCount = false
                state.postRemoveCount = action.payload
            })
            .addCase(actionPostReportCount.rejected, (state) => {
                state.loadingPostReportCount = false
            })

            //get Boost Post Report List
            .addCase(actionBoostPostList.pending, (state) => {
                state.loadingBoostPostList = true
            })
            .addCase(actionBoostPostList.fulfilled, (state, action) => {
                state.loadingBoostPostList = false
                state.boostPostList = action.payload
            })
            .addCase(actionBoostPostList.rejected, (state) => {
                state.loadingBoostPostList = false
            })
    }
})
export const {
    resetPostResponse,
    resetAddPostResponse,
    resetEditPostResponse,
    resetPostDetailsResponse,
    resetDeletePostResponse,
    resetGetLikesResponse,
    resetGetCommentsResponse,
    resetGetReportResponse,
    resetPostCommentRemoveResponse,
    resetPostRemoveResponse,
    resetPostReportCountResponse,
    resetBoostPostListResponse
} = appPostSlice.actions

export default appPostSlice.reducer