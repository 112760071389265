// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { BULL_INFORMATION, ADD_BULL_INFORMATION, GET_BULL_INFORMATION, EDIT_BULL_INFORMATION, DELETE_BULL_INFORMATION } from '../../common/constant'

export const actionBull = createAsyncThunk('appBull/actionBull', async (thunkAPI) => {
    return await post(BULL_INFORMATION, thunkAPI)
})

export const actionAddBull = createAsyncThunk('appBull/actionAddBull', async (params, thunkAPI) => {
    return await post(ADD_BULL_INFORMATION, params, thunkAPI)
})

export const actionGetBull = createAsyncThunk('appBull/actionGetBull', async (params, thunkAPI) => {
    return await post(GET_BULL_INFORMATION, params, thunkAPI)
})

export const actionEditBull = createAsyncThunk('appBull/actionEditBull', async (params, thunkAPI) => {
    return await post(EDIT_BULL_INFORMATION, params, thunkAPI)
})
export const actionDeleteBull = createAsyncThunk('appBull/actionDeleteBull', async (params, thunkAPI) => {
    return await post(DELETE_BULL_INFORMATION, params, thunkAPI)
})

export const appBullSlice = createSlice({
    name: 'appBull',
    initialState: {
        //list
        loading: false,
        bull: null,

        //add
        loadingAdd: false,
        addBull: null,

        //get
        loadingGet: false,
        getBull: null,

        //edit
        loadingEdit: false,
        editBull: null,

        //delete
        loadingDelete: false,
        deleteBull: null
    },
    reducers: {
        //reset or delete Add Bull store data
        resetBullResponse: (state) => {
            state.bull = null
        },
        resetAddBullResponse: (state) => {
            state.addBull = null
        },
        resetGetBullResponse: (state) => {
            state.getBull = null
        },
        resetEditBullResponse: (state) => {
            state.editBull = null
        },
        resetDeleteBullResponse: (state) => {
            state.deleteBull = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionBull.pending, (state) => {
                state.loading = true
            })
            .addCase(actionBull.fulfilled, (state, action) => {
                state.loading = false
                state.bull = action.payload
            })
            .addCase(actionBull.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddBull.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddBull.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addBull = action.payload
            })
            .addCase(actionAddBull.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetBull.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetBull.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getBull = action.payload
            })
            .addCase(actionGetBull.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditBull.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditBull.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editBull = action.payload
            })
            .addCase(actionEditBull.rejected, (state) => {
                state.loadingEdit = false
            })
            //delete
            .addCase(actionDeleteBull.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionDeleteBull.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteBull = action.payload
            })
            .addCase(actionDeleteBull.rejected, (state) => {
                state.loadingDelete = false
            })
    }
})
export const { resetBullResponse, resetAddBullResponse, resetGetBullResponse, resetEditBullResponse, resetDeleteBullResponse } = appBullSlice.actions

export default appBullSlice.reducer