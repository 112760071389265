import axios from 'axios'
import { API_TIMEOUT } from '../app_constant'

export const getMyConfig = (objConfig) => {
    var config = {}

    config.timeout = API_TIMEOUT
    config.headers = {}

    if (objConfig !== null && objConfig?.isOepnApi === false) {
        config.headers.token = 'SENDING_TOKEN'
    }

    if (objConfig !== null && objConfig.isMultipart === true) {
        config.headers["Content-Type"] = "multipart/form-data"
        config.headers["accept"] = "*/*"
    }

    return config
}

export const post = async (url, params, thunkAPI, isOepnApi = false) => {
    try {
        const response = await axios.post(url, params, getMyConfig({
            isOepnApi
        }))
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const multipart = async (url, params, thunkAPI, isOepnApi = false) => {
    try {

        const response = await axios.post(url, params, {
            ...getMyConfig({
                isOepnApi,
                isMultipart: true
            })
        })
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const put = async (url, params, thunkAPI, isOepnApi = false) => {
    try {
        const response = await axios.put(url, params, getMyConfig({
            isOepnApi
        }))
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}

export const get = async (url, thunkAPI, isOepnApi = false) => {
    try {
        const response = await axios.get(url, getMyConfig({
            isOepnApi
        }))
        return response.data
    } catch (error) {
        return thunkAPI.rejectWithValue({ custom_error: error.message })
    }
}