// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { ADVERTISMENT_DMS_LIST, ADVERTISEMENT_DMS_ADD, ADVERTISEMENT_DMS_EDIT, ADVERTISEMENT_DMS_DETAILS, ADVERTISEMENT_DMS_DELETE, PRIZE_DMS_DROPDOWN_LIST } from '../../common/constant'

export const actionAdvertisementDMS = createAsyncThunk('appAdvertisementDMS/actionAdvertisementDMS', async (params, thunkAPI) => {
    return await post(ADVERTISMENT_DMS_LIST, params, thunkAPI)
})

export const actionAdvertisementDMSAdd = createAsyncThunk('appAdvertisementDMS/actionAdvertisementDMSAdd', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DMS_ADD, params, thunkAPI)
})

export const actionAdvertisementDMSDetails = createAsyncThunk('appAdvertisementDMS/actionAdvertisementDMSDetails', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DMS_DETAILS, params, thunkAPI)
})

export const actionAdvertisementDMSEdit = createAsyncThunk('appAdvertisementDMS/actionAdvertisementDMSEdit', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DMS_EDIT, params, thunkAPI)
})

export const actionAdvertisementDMSDelete = createAsyncThunk('appAdvertisementDMS/actionAdvertisementDMSDelete', async (params, thunkAPI) => {
    return await post(ADVERTISEMENT_DMS_DELETE, params, thunkAPI)
})

// Dropdown api
export const actionPrizeDMSList = createAsyncThunk('appAdvertisementDMS/actionPrizeDMSList', async (params, thunkAPI) => {
    return await post(PRIZE_DMS_DROPDOWN_LIST, params, thunkAPI)
})

export const appAdvertisementSLice = createSlice({
    name: 'appAdvertisementDMS',
    initialState: {
        //list
        loadingAdvertisementDMS: false,
        advertisementDMS: null,

        //add
        loadingAddAdvertisement: false,
        addAdvertisementDMS: null,

        //get
        loadingGet: false,
        advertisementDMSDetails: null,

        //edit
        loadingEdit: false,
        editAdvertisementDMS: null,

        //delete
        loadingDelete: false,
        deleteAdvertisementDMS: null,

        //dropdown list
        loadingList: false,
        listPrizeDMS: null

    },
    reducers: {
        //reset or delete Add Insurance State store data
        resetAdvertisementDMSResponse: (state) => {
            state.advertisementDMS = null
        },
        resetAddAdvertisementDMSResponse: (state) => {
            state.addAdvertisementDMS = null
        },
        resetAdvertisementDMSDetailsResponse: (state) => {
            state.advertisementDMSDetails = null
        },
        resetEditAdvertisementDMSResponse: (state) => {
            state.editAdvertisementDMS = null
        },
        resetDeleteAdvertisementDMSResponse: (state) => {
            state.deleteAdvertisementDMS = null
        },
        resetListPrizeDMSResponse: (state) => {
            state.listPrizeDMS = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionAdvertisementDMS.pending, (state) => {
                state.loadingAdvertisementDMS = true
            })
            .addCase(actionAdvertisementDMS.fulfilled, (state, action) => {
                state.loadingAdvertisementDMS = false
                state.advertisementDMS = action.payload
            })
            .addCase(actionAdvertisementDMS.rejected, (state) => {
                state.loadingAdvertisementDMS = false
            })

            //add
            .addCase(actionAdvertisementDMSAdd.pending, (state) => {
                state.loadingAddAdvertisement = true
            })
            .addCase(actionAdvertisementDMSAdd.fulfilled, (state, action) => {
                state.loadingAddAdvertisement = false
                state.addAdvertisementDMS = action.payload
            })
            .addCase(actionAdvertisementDMSAdd.rejected, (state) => {
                state.loadingAddAdvertisement = false
            })

            //get
            .addCase(actionAdvertisementDMSDetails.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionAdvertisementDMSDetails.fulfilled, (state, action) => {
                state.loadingGet = false
                state.advertisementDMSDetails = action.payload
            })
            .addCase(actionAdvertisementDMSDetails.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionAdvertisementDMSEdit.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionAdvertisementDMSEdit.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editAdvertisementDMS = action.payload
            })
            .addCase(actionAdvertisementDMSEdit.rejected, (state) => {
                state.loadingEdit = false
            })

            //delete
            .addCase(actionAdvertisementDMSDelete.pending, (state) => {
                state.loadingDelete = true
            })
            .addCase(actionAdvertisementDMSDelete.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteAdvertisementDMS = action.payload
            })
            .addCase(actionAdvertisementDMSDelete.rejected, (state) => {
                state.loadingDelete = false
            })

            //list
            .addCase(actionPrizeDMSList.pending, (state) => {
                state.loadingList = true
            })
            .addCase(actionPrizeDMSList.fulfilled, (state, action) => {
                state.loadingList = false
                state.listPrizeDMS = action.payload
            })
            .addCase(actionPrizeDMSList.rejected, (state) => {
                state.loadingList = false
            })

    }
})
export const { resetAdvertisementDMSResponse, resetAddAdvertisementDMSResponse, resetEditAdvertisementDMSResponse, resetAdvertisementDMSDetailsResponse, resetDeleteAdvertisementDMSResponse, resetListPrizeDMSResponse } = appAdvertisementSLice.actions

export default appAdvertisementSLice.reducer