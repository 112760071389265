import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { REPORT_MASTER_ADD, REPORT_MASTER_DETAIL, REPORT_MASTER_EDIT, REPORT_MASTER_LIST } from '../../common/constant'

//** import apis

//List
export const actionReportMasterList = createAsyncThunk('reportMaster/actionReportMasterList', async (params, thunkAPI) => {
    return await post(REPORT_MASTER_LIST, params, thunkAPI)
})

//add
export const actionReportMasterAdd = createAsyncThunk('reportMaster/actionReportMasterAdd', async (params, thunkAPI) => {
    return await post(REPORT_MASTER_ADD, params, thunkAPI)
})

//Details
export const actionReportMasterDetail = createAsyncThunk('reportMaster/actionReportMasterDetail', async (params, thunkAPI) => {
    return await post(REPORT_MASTER_DETAIL, params, thunkAPI)
})

//Edit
export const actionReportMasterEdit = createAsyncThunk('reportMaster/actionReportMasterEdit', async (params, thunkAPI) => {
    return await post(REPORT_MASTER_EDIT, params, thunkAPI)
})

export const reportMasterSlice = createSlice({
    name: 'reportMaster',
    initialState: {
        //list
        loadingReportMasterList: false,
        reportMasterList: null,
        
        //add
        loadingReportMasterAdd: false,
        reportMasterAdd: null,

        //Detail
        loadingReportMasterDetail: false,
        reportMasterDetail: null,

        //Edit
        loadingReportMasterEdit: false,
        reportMasterEdit: null

    },
    reducers: {
        // List store
        resetReportMasterListResponse: (state) => {
            state.reportMasterList = null
        },

        // Add store
        resetReportMasterAddResponse: (state) => {
            state.reportMasterAdd = null
        },

        // Detail store
        resetReportMasterDetailResponse: (state) => {
            state.reportMasterDetail = null
        },

        // Edit store
        resetReportMasterEditResponse: (state) => {
            state.reportMasterEdit = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionReportMasterList.pending, (state) => {
                state.loadingReportMasterList = true
            })
            .addCase(actionReportMasterList.fulfilled, (state, action) => {
                state.loadingReportMasterList = false
                state.reportMasterList = action.payload
            })
            .addCase(actionReportMasterList.rejected, (state) => {
                state.loadingReportMasterList = false
            })

            //Add
            .addCase(actionReportMasterAdd.pending, (state) => {
                state.loadingReportMasterAdd = true
            })
            .addCase(actionReportMasterAdd.fulfilled, (state, action) => {
                state.loadingReportMasterAdd = false
                state.reportMasterAdd = action.payload
            })
            .addCase(actionReportMasterAdd.rejected, (state) => {
                state.loadingReportMasterAdd = false
            })

            //Details
            .addCase(actionReportMasterDetail.pending, (state) => {
                state.loadingReportMasterDetail = true
            })
            .addCase(actionReportMasterDetail.fulfilled, (state, action) => {
                state.loadingReportMasterDetail = false
                state.reportMasterDetail = action.payload
            })
            .addCase(actionReportMasterDetail.rejected, (state) => {
                state.loadingReportMasterDetail = false
            })

            //Edit
            .addCase(actionReportMasterEdit.pending, (state) => {
                state.loadingReportMasterEdit = true
            })
            .addCase(actionReportMasterEdit.fulfilled, (state, action) => {
                state.loadingReportMasterEdit = false
                state.reportMasterEdit = action.payload
            })
            .addCase(actionReportMasterEdit.rejected, (state) => {
                state.loadingReportMasterEdit = false
            })


    }
})
export const { resetReportMasterListResponse, resetReportMasterAddResponse, resetReportMasterDetailResponse, resetReportMasterEditResponse} = reportMasterSlice.actions

export default reportMasterSlice.reducer