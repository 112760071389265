import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REVIEW_LIST } from '../../common/constant'
import { post } from '../../common/api'

//List
export const actionReviewList = createAsyncThunk('appReview/actionReviewList', async (params, thunkAPI) => {
    return await post(REVIEW_LIST, params, thunkAPI)
})

export const reviewSlice = createSlice({
    name: 'appReview',
    initialState: {
        //list
        loadingReviewListList: false,
        reviewListList: null

    },
    reducers: {
        // List store
        resetReviewListResponse: (state) => {
            state.reviewListList = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionReviewList.pending, (state) => {
                state.loadingReviewListList = true
            })
            .addCase(actionReviewList.fulfilled, (state, action) => {
                state.loadingReviewListList = false
                state.reviewListList = action.payload
            })
            .addCase(actionReviewList.rejected, (state) => {
                state.loadingReviewListList = false
            })   
    }
})
export const { resetReviewListResponse } = reviewSlice.actions

export default reviewSlice.reducer