import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post } from '../../common/api'
import { NOTIFICATION_MASTER_ADD, NOTIFICATION_MASTER_DETAILS, NOTIFICATION_MASTER_EDIT, NOTIFICATION_MASTER_LIST } from '../../common/constant'

//** import apis

//List
export const actionNotificationMasterList = createAsyncThunk('notificationMaster/actionNotificationMasterList', async (params, thunkAPI) => {
    return await post(NOTIFICATION_MASTER_LIST, params, thunkAPI)
})

//Add
export const actionNotificationMasterAdd = createAsyncThunk('notificationMaster/actionNotificationMasterAdd', async (params, thunkAPI) => {
    return await post(NOTIFICATION_MASTER_ADD, params, thunkAPI)
})

//Details
export const actionNotificationMasterDetails = createAsyncThunk('notificationMaster/actionNotificationMasterDetails', async (params, thunkAPI) => {
    return await post(NOTIFICATION_MASTER_DETAILS, params, thunkAPI)
})

//Details
export const actionNotificationMasterEdit = createAsyncThunk('notificationMaster/actionNotificationMasterEdit', async (params, thunkAPI) => {
    return await post(NOTIFICATION_MASTER_EDIT, params, thunkAPI)
})

export const notificationMasterSlice = createSlice({
    name: 'notificationMaster',
    initialState: {
        //list
        loadingNotificationMasterList: false,
        notificationMasterList: null,

        //Add
        loadingNotificationMasterAdd: false,
        notificationMasterAdd: null,

        //Details
        loadingNotificationMasterDetail: false,
        notificationMasterDetail: null,

        //Edit
        loadingNotificationMasterEdit: false,
        notificationMasterEdit: null
    },
    reducers: {
        // List store
        resetNotificationMasterListResponse: (state) => {
            state.notificationMasterList = null
        },

        // Add store
        resetNotificationMasterAddResponse: (state) => {
            state.notificationMasterAdd = null
        },

        // Detail store
        resetNotificationMasterDetailResponse: (state) => {
            state.notificationMasterDetail = null
        },

        // Edit store
        resetNotificationMasterEditResponse: (state) => {
            state.notificationMasterEdit = null
        }
    },
    extraReducers: builder => {
        builder
            //List
            .addCase(actionNotificationMasterList.pending, (state) => {
                state.loadingNotificationMasterList = true
            })
            .addCase(actionNotificationMasterList.fulfilled, (state, action) => {
                state.loadingNotificationMasterList = false
                state.notificationMasterList = action.payload
            })
            .addCase(actionNotificationMasterList.rejected, (state) => {
                state.loadingNotificationMasterList = false
            })

            //Add
            .addCase(actionNotificationMasterAdd.pending, (state) => {
                state.loadingNotificationMasterAdd = true
            })
            .addCase(actionNotificationMasterAdd.fulfilled, (state, action) => {
                state.loadingNotificationMasterAdd = false
                state.notificationMasterAdd = action.payload
            })
            .addCase(actionNotificationMasterAdd.rejected, (state) => {
                state.loadingNotificationMasterAdd = false
            })

            //Details
            .addCase(actionNotificationMasterDetails.pending, (state) => {
                state.loadingNotificationMasterDetail = true
            })
            .addCase(actionNotificationMasterDetails.fulfilled, (state, action) => {
                state.loadingNotificationMasterDetail = false
                state.notificationMasterDetail = action.payload
            })
            .addCase(actionNotificationMasterDetails.rejected, (state) => {
                state.loadingNotificationMasterDetail = false
            })
            
            //Edit
            .addCase(actionNotificationMasterEdit.pending, (state) => {
                state.loadingNotificationMasterEdit = true
            })
            .addCase(actionNotificationMasterEdit.fulfilled, (state, action) => {
                state.loadingNotificationMasterEdit = false
                state.notificationMasterEdit = action.payload
            })
            .addCase(actionNotificationMasterEdit.rejected, (state) => {
                state.loadingNotificationMasterEdit = false
            })            

    }
})
export const { resetNotificationMasterListResponse, resetNotificationMasterAddResponse, resetNotificationMasterDetailResponse, resetNotificationMasterEditResponse } = notificationMasterSlice.actions

export default notificationMasterSlice.reducer