// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'

import userNavbar from '../store/navbar'
import country from '../store/country'
import state from '../store/state'
import language from '../store/language'
import district from '../store/district'
import village from '../store/village'
import tehsil from '../store/tehsil'
import user from '../store/user'
import bull from '../store/bull_info'
import totalRaceInfo from '../store/total_race_info'
import totalFinalRaceInfo from '../store/final_race_info'
import advertisementDMS from '../store/advertisement_dms'
import appEvent from '../store/event'
import appEventOrganizer from '../store/event_organizer'
import appEventParticipant from '../store/event_participant'
import appEventPrize from '../store/event_prize'
import appEventWinningRule from '../store/event_winning_rule'
import appEventRound from '../store/event_round'
import appParticipantReport from '../store/report'
import appPrizeDMS from '../store/prize_dms'
import appSocialIcons from '../store/social_icons'
import gameType from '../store/game_type'
import prizeType from '../store/prize_type'
import post from '../store/post'
import notificationMaster from '../store/notification_master'
import reportMaster from '../store/report_master'
import appEventImageDocument from '../store/event_image_document'
import appDashboard from '../store/dashboard'
import appEventRules from '../store/event_rules'
import appSettingMaster from '../store/setting'
import appReview from '../store/reviews'
import appDirectory from '../store/directories'
import appNotification from '../store/notification'
import appVideo from '../store/video'
import appFinalreports from '../store/final_reports'
import appPremiumAdvertisement from '../store/premium_advertisement'

const rootReducer = {
  auth,
  navbar,
  layout,
  userNavbar,
  country,
  state,
  district,
  tehsil,
  language,
  village,
  user,
  bull,
  totalRaceInfo,
  totalFinalRaceInfo,
  advertisementDMS,
  appEvent,
  appEventOrganizer,
  appEventParticipant,
  appEventPrize,
  appEventWinningRule,
  appEventRound,
  appParticipantReport,
  appPrizeDMS,
  appSocialIcons,
  gameType,
  prizeType,
  post,
  notificationMaster,
  reportMaster,
  appEventImageDocument,
  appDashboard,
  appEventRules,
  appSettingMaster,
  appReview,
  appDirectory,
  appNotification,
  appVideo,
  appFinalreports,
  appPremiumAdvertisement
}

export default rootReducer
