// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { post, put } from '../../common/api'
import { ADD_TEHSIL, EDIT_TEHSIL, GET_TEHSIL, TEHSIL, DELETE_TEHSIL } from '../../common/constant'

export const actionTehsil = createAsyncThunk('appTehsil/actionTehsil', async (params, thunkAPI) => {
    return await post(TEHSIL, params, thunkAPI)
})

export const actionAddTehsil = createAsyncThunk('appTehsil/actionAddTehsil', async (params, thunkAPI) => {
    return await put(ADD_TEHSIL, params, thunkAPI)
})

export const actionGetTehsil = createAsyncThunk('appTehsil/actionGetTehsil', async (params, thunkAPI) => {
    return await post(GET_TEHSIL, params, thunkAPI)
})

export const actionEditTehsil = createAsyncThunk('appTehsil/actionEditTehsil', async (params, thunkAPI) => {
    return await post(EDIT_TEHSIL, params, thunkAPI)
})
export const actionDeleteTehsil = createAsyncThunk('appTehsil/actionDeleteTehsil', async (params, thunkAPI) => {
    return await post(DELETE_TEHSIL, params, thunkAPI)
})

export const appTehsilSlice = createSlice({
    name: 'appTehsil',
    initialState: {
        //list
        loading: false,
        tehsil: null,

        //add
        loadingAdd: false,
        addTehsil: null,

        //get
        loadingGet: false,
        getTehsil: null,

        //edit
        loadingEdit: false,
        editTehsil: null,

        //edit
        loadingDelete: false,
        deleteTehsil: null

    },
    reducers: {
        //reset or delete Add Tehsil store data
        resetTehsilResponse: (state) => {
            state.tehsil = null
        },
        resetAddTehsilResponse: (state) => {
            state.addTehsil = null
        },
        resetGetTehsilResponse: (state) => {
            state.getTehsil = null
        },
        resetEditTehsilResponse: (state) => {
            state.editTehsil = null
        },
        resetDeleteTehsilResponse: (state) => {
            state.deleteTehsil = null
        }
    },
    extraReducers: builder => {
        builder
            //list
            .addCase(actionTehsil.pending, (state) => {
                state.loading = true
            })
            .addCase(actionTehsil.fulfilled, (state, action) => {
                state.loading = false
                state.tehsil = action.payload
            })
            .addCase(actionTehsil.rejected, (state) => {
                state.loading = false
            })

            //add
            .addCase(actionAddTehsil.pending, (state) => {
                state.loadingAdd = true
            })
            .addCase(actionAddTehsil.fulfilled, (state, action) => {
                state.loadingAdd = false
                state.addTehsil = action.payload
            })
            .addCase(actionAddTehsil.rejected, (state) => {
                state.loadingAdd = false
            })

            //get
            .addCase(actionGetTehsil.pending, (state) => {
                state.loadingGet = true
            })
            .addCase(actionGetTehsil.fulfilled, (state, action) => {
                state.loadingGet = false
                state.getTehsil = action.payload
            })
            .addCase(actionGetTehsil.rejected, (state) => {
                state.loadingGet = false
            })

            //edit
            .addCase(actionEditTehsil.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionEditTehsil.fulfilled, (state, action) => {
                state.loadingEdit = false
                state.editTehsil = action.payload
            })
            .addCase(actionEditTehsil.rejected, (state) => {
                state.loadingEdit = false
            })

            //Delete
            .addCase(actionDeleteTehsil.pending, (state) => {
                state.loadingEdit = true
            })
            .addCase(actionDeleteTehsil.fulfilled, (state, action) => {
                state.loadingDelete = false
                state.deleteTehsil = action.payload
            })
            .addCase(actionDeleteTehsil.rejected, (state) => {
                state.loadingEdit = false
            })
    }
})
export const { resetTehsilResponse, resetAddTehsilResponse, resetGetTehsilResponse, resetEditTehsilResponse, resetDeleteTehsilResponse } = appTehsilSlice.actions

export default appTehsilSlice.reducer