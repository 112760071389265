import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { multipart, post } from "../../common/api"
import {
    EVENT_ROUNDS,
    EVENT_ROUND_PARTICIPANTS,
    EVENT_ROUNDS_ADD,
    EVENT_ROUNDS_PARTICIPANTS,
    ROUND_PARTICIPANTS_SCORE_ADD,
    ROUND_PARTICIPANTS_SCORE_EDIT,
    EVENT_ROUND_UPDATE_STATUS,
    EVENT_ROUND_PARTICIPANT_ASSIGN_PRIZE,
    EVENT_ROUND_PRIZES,
    EVENT_ROUND_PARTICIPANT_SWAP,
    EVENT_ROUND_EDIT,
    EVENT_ROUND_EDIT_DETAILS,
    EVENT_PARTICIPANT_ROUND_SHIFT,
    ROUND_PARTICIPANT_DELETE,
    MOVE_PARTICIPANT_FROM_ROUND,
    PARTICIPANT_MOVE_TO_ROUND,
    UPDATE_CURRENT_TOKEN,
    RACE_TIME_WISE_PARTICIPANTS,
    EVENT_PARTICIPANT_GENERATE_ROUND,
    EVENT_PARTICIPANT_ADD_TO_ROUND,
    EVENT_PARTICIPANT_ROUND_LIST
} from '../../common/constant'

export const actionEventRounds = createAsyncThunk('appEventRound/actionEventRounds', async (thunkAPI) => {
    return await post(EVENT_ROUNDS, thunkAPI)
})

export const actionEventRoundsParticipants = createAsyncThunk('appEventRound/actionEventRoundsParticipants', async (thunkAPI) => {
    return await post(EVENT_ROUND_PARTICIPANTS, thunkAPI)
})

export const actionEventRoundsAdd = createAsyncThunk('appEventRound/actionEventRoundsAdd', async (thunkAPI) => {
    return await post(EVENT_ROUNDS_ADD, thunkAPI)
})

export const actionEventRoundsParticipantsDetails = createAsyncThunk('appEventRound/actionEventRoundsParticipantsDetails', async (thunkAPI) => {
    return await post(EVENT_ROUNDS_PARTICIPANTS, thunkAPI)
})

export const actionRoundParticipantsScoreAdd = createAsyncThunk('appEventRound/actionRoundParticipantsScoreAdd', async (thunkAPI) => {
    return await post(ROUND_PARTICIPANTS_SCORE_ADD, thunkAPI)
})

export const actionRoundParticipantsScoreEdit = createAsyncThunk('appEventRound/actionRoundParticipantsScoreEdit', async (thunkAPI) => {
    return await post(ROUND_PARTICIPANTS_SCORE_EDIT, thunkAPI)
})

export const actionEventRoundUpdateStatus = createAsyncThunk('appEventRound/actionEventRoundUpdateStatus', async (thunkAPI) => {
    return await post(EVENT_ROUND_UPDATE_STATUS, thunkAPI)
})

export const actionEventRoundParticipantAssignPrize = createAsyncThunk('appEventRound/actionEventRoundParticipantAssignPrize', async (thunkAPI) => {
    return await post(EVENT_ROUND_PARTICIPANT_ASSIGN_PRIZE, thunkAPI)
})

export const actionEventRoundPrizes = createAsyncThunk('appEventRound/actionEventRoundPrizes', async (thunkAPI) => {
    return await post(EVENT_ROUND_PRIZES, thunkAPI)
})

export const actionEventCompletedRound = createAsyncThunk('appEventRound/actionEventCompletedRound', async (thunkAPI) => {
    return await post(EVENT_ROUNDS, thunkAPI)
})

export const actionEventRoundParticipantSwap = createAsyncThunk('appEventRound/actionEventRoundParticipantSwap', async (thunkAPI) => {
    return await post(EVENT_ROUND_PARTICIPANT_SWAP, thunkAPI)
})

export const actionEventRoundParticipantSwapped = createAsyncThunk('appEventRound/actionEventRoundParticipantSwapped', (params) => {
    return params
})

export const actionEventRoundEdit = createAsyncThunk('appEventRound/actionEventRoundEdit', async (thunkAPI) => {
    return await multipart(EVENT_ROUND_EDIT, thunkAPI)
})

export const actionEventRoundEditDetails = createAsyncThunk('appEventRound/actionEventRoundEditDetails', async (thunkAPI) => {
    return await post(EVENT_ROUND_EDIT_DETAILS, thunkAPI)
})
export const actionEventRoundParticipantShift = createAsyncThunk('appEventRound/actionEventRoundParticipantShift', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_ROUND_SHIFT, thunkAPI)
})

export const actionRoundParticipantDelete = createAsyncThunk('appEventRound/actionRoundParticipantDelete', async (thunkAPI) => {
    return await post(ROUND_PARTICIPANT_DELETE, thunkAPI)
})

export const actionRoundParticipantMove = createAsyncThunk('appEventRound/actionRoundParticipantMove', async (thunkAPI) => {
    return await post(MOVE_PARTICIPANT_FROM_ROUND, thunkAPI)
})

export const actionParticipantMoveToRound = createAsyncThunk('appEventRound/actionParticipantMoveToRound', async (thunkAPI) => {
    return await post(PARTICIPANT_MOVE_TO_ROUND, thunkAPI)
})

export const actionUpdateCurrentToken = createAsyncThunk('appEventRound/actionUpdateCurrentToken', async (thunkAPI) => {
    return await post(UPDATE_CURRENT_TOKEN, thunkAPI)
})

export const actionRaceTimeWiseParticipants = createAsyncThunk('appEventRound/actionRaceTimeWiseParticipants', async (thunkAPI) => {
    return await post(RACE_TIME_WISE_PARTICIPANTS, thunkAPI)
})

export const actionEventParticipantGenerateRound = createAsyncThunk('appEventRound/actionEventParticipantGenerateRound', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_GENERATE_ROUND, thunkAPI)
})

export const actionEventParticipantAddToRound = createAsyncThunk('appEventRound/actionEventParticipantAddToRound', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_ADD_TO_ROUND, thunkAPI)
})
// Get details of event and event round and Race time count
export const actionEventParticipantRoundList = createAsyncThunk('appEventRound/actionEventParticipantRoundList', async (thunkAPI) => {
    return await post(EVENT_PARTICIPANT_ROUND_LIST, thunkAPI)
})

export const appEventRoundSlice = createSlice({
    name: 'appEventRound',
    initialState: {
        //event-rounds/list
        loadingEventRoundsList: false,
        eventRounds: null,

        //event-rounds/participants
        loadingEventRoundsParticipants: false,
        eventRoundsParticipants: null,

        //event-rounds/add
        loadingEventRoundsAdd: false,
        eventRoundsAdd: null,

        //event-rounds/participants
        loadingEventRoundsParticipantsDetails: false,
        eventRoundsParticipantsDetails: null,

        //event-rounds/participants score add
        loadingRoundParticipantScoreAdd: false,
        roundParticipantScoreAdd: null,

        //event-rounds/participants score edit
        loadingRoundParticipantScoreEdit: false,
        roundParticipantScoreEdit: null,

        //EventRoundParticipantAssignPrize
        loadingEventRoundParticipantAssignPrize: false,
        eventRoundParticipantAssignPrize: null,

        //EventRoundPrizes
        loadingEventRoundPrizes: false,
        eventRoundPrizes: null,

        //EventCompletedRound
        loadingEventCompletedRound: false,
        eventCompletedRound: null,

        // Event Round Participant Swap
        loadingEventRoundParticipantSwap: false,
        eventRoundParticipantSwap: null,

        // Event Round Participant Swap
        loadingEventRoundParticipantSwapped: false,
        eventRoundParticipantSwapped: null,

        //EventRoundEdit
        loadingEventRoundEdit: false,
        eventRoundEdit: null,

        //event rounde edit details
        loadingEventEditRoundDetails: false,
        eventEditRoundDetails: null,

        // event round participant shift
        loadingEventRoundParticipantShift: false,
        eventRoundParticipantShift: null,

        //round participant delete
        loadingRoundParticipantDelete: false,
        roundParticipantDelete: null,

        //round participant move
        loadingRoundParticipantMove: false,
        roundParticipantMove: null,

        //add participant to round
        loadingRoundParticipantAdd: false,
        participantToRound: null,

        //update current token
        loadingUpdateCurrentToken: false,
        updateCurrentToken: false,

        //race time wise participants
        loadingRaceTimeWiseWithParticipants: false,
        raceTimeWiseWithParticipants: false,

        //EventParticipantGenerateRound
        loadingEventParticipantGenerateRound: false,
        eventParticipantGenerateRound: null,

        //EventParticipantGenerateRound
        loadingEventRoundUpdateStatus: false,
        eventRoundUpdateStatus: null,

        //EventParticipant add directly in round
        loadingAddParticipantRoundStatus: false,
        eventRoundParticipantAddStatus: null,

        //EventParticipant Round Title and Count of timing 
        loadingParticipantRoundList: false,
        eventRoundParticipantList: null
    },
    reducers: {
        resetEventRounds: (state) => {
            state.eventRounds = null
        },
        resetEventRoundParticipant: (state) => {
            state.eventRoundsParticipants = null
        },
        resetEventRoundsAdd: (state) => {
            state.eventRoundsAdd = null
        },
        resetEventRoundsParticipantsDetails: (state) => {
            state.eventRoundsParticipantsDetails = null
        },
        resetRoundParticipantsScoreAdd: (state) => {
            state.roundParticipantScoreAdd = null
        },
        resetRoundParticipantsScoreEdit: (state) => {
            state.roundParticipantScoreEdit = null
        },
        resetEventRoundUpdateStatus: (state) => {
            state.eventRoundUpdateStatus = null
        },
        resetEventRoundParticipantAssignPrize: (state) => {
            state.eventRoundParticipantAssignPrize = null
        },
        resetEventRoundPrizes: (state) => {
            state.eventRoundPrizes = null
        },
        resetEventCompletedRound: (state) => {
            state.eventCompletedRound = null
        },
        resetEventRoundParticipantSwap: (state) => {
            state.eventRoundParticipantSwap = null
        },
        resetEventRoundParticipantSwapped: (state) => {
            state.eventRoundParticipantSwapped = null
        },
        resetEventRoundEdit: (state) => {
            state.eventRoundEdit = null
        },
        resetEditEventRoundDetails: (state) => {
            state.eventEditRoundDetails = null
        },
        resetRoundParticipantDelete: (state) => {
            state.roundParticipantDelete = null
        },
        resetRoundParticipantMove: (state) => {
            state.roundParticipantMove = null
        },
        resetParticipantToRound: (state) => {
            state.participantToRound = null
        },
        resetUpdateCurrentToken: (state) => {
            state.updateCurrentToken = null
        },
        resetRaceTimeWiseParticipants: (state) => {
            state.raceTimeWiseWithParticipants = null
        },
        resetEventParticipantGenerateRound: (state) => {
            state.eventParticipantGenerateRound = null
        },
        resetAddEventParticipantRoundResponse: (state) => {
            state.eventRoundParticipantAddStatus = null
        },
        resetEventParticipantRoundListResponse: (state) => {
            state.eventRoundParticipantList = null
        }
    },
    extraReducers: builder => {
        builder
            //events rounds
            .addCase(actionEventRounds.pending, (state) => {
                state.loadingEventRoundsList = true
            })
            .addCase(actionEventRounds.fulfilled, (state, action) => {
                state.loadingEventRoundsList = false
                state.eventRounds = action.payload
            })
            .addCase(actionEventRounds.rejected, (state) => {
                state.loadingEventRoundsList = false
            })

            //event round participants
            .addCase(actionEventRoundsParticipants.pending, (state) => {
                state.loadingEventRoundsParticipants = true
            })
            .addCase(actionEventRoundsParticipants.fulfilled, (state, action) => {
                state.loadingEventRoundsParticipants = false
                state.eventRoundsParticipants = action.payload
            })
            .addCase(actionEventRoundsParticipants.rejected, (state) => {
                state.loadingEventRoundsParticipants = false
            })

            //event rounds add
            .addCase(actionEventRoundsAdd.pending, (state) => {
                state.loadingEventRoundsAdd = true
            })
            .addCase(actionEventRoundsAdd.fulfilled, (state, action) => {
                state.loadingEventRoundsAdd = false
                state.eventRoundsAdd = action.payload
            })
            .addCase(actionEventRoundsAdd.rejected, (state) => {
                state.loadingEventRoundsAdd = false
            })

            //actionEventRoundsParticipantsDetails
            .addCase(actionEventRoundsParticipantsDetails.pending, (state) => {
                state.loadingEventRoundsParticipantsDetails = true
            })
            .addCase(actionEventRoundsParticipantsDetails.fulfilled, (state, action) => {
                state.loadingEventRoundsParticipantsDetails = false
                state.eventRoundsParticipantsDetails = action.payload
            })
            .addCase(actionEventRoundsParticipantsDetails.rejected, (state) => {
                state.loadingEventRoundsParticipantsDetails = false
            })

            //round participants score add
            .addCase(actionRoundParticipantsScoreAdd.pending, (state) => {
                state.loadingRoundParticipantScoreAdd = true
            })
            .addCase(actionRoundParticipantsScoreAdd.fulfilled, (state, action) => {
                state.loadingRoundParticipantScoreAdd = false
                state.roundParticipantScoreAdd = action.payload
            })
            .addCase(actionRoundParticipantsScoreAdd.rejected, (state) => {
                state.loadingRoundParticipantScoreAdd = false
            })

            //round participants score edit
            .addCase(actionRoundParticipantsScoreEdit.pending, (state) => {
                state.loadingRoundParticipantScoreEdit = true
            })
            .addCase(actionRoundParticipantsScoreEdit.fulfilled, (state, action) => {
                state.loadingRoundParticipantScoreEdit = false
                state.roundParticipantScoreEdit = action.payload
            })
            .addCase(actionRoundParticipantsScoreEdit.rejected, (state) => {
                state.loadingRoundParticipantScoreEdit = false
            })

            //event round update status
            .addCase(actionEventRoundUpdateStatus.pending, (state) => {
                state.loadingEventRoundUpdateStatus = true
            })
            .addCase(actionEventRoundUpdateStatus.fulfilled, (state, action) => {
                state.loadingEventRoundUpdateStatus = false
                state.eventRoundUpdateStatus = action.payload
            })
            .addCase(actionEventRoundUpdateStatus.rejected, (state) => {
                state.loadingEventRoundUpdateStatus = false
            })

            //eventRoundParticipantAssignPrize 
            .addCase(actionEventRoundParticipantAssignPrize.pending, (state) => {
                state.loadingEventRoundParticipantAssignPrize = true
            })
            .addCase(actionEventRoundParticipantAssignPrize.fulfilled, (state, action) => {
                state.loadingEventRoundParticipantAssignPrize = false
                state.eventRoundParticipantAssignPrize = action.payload
            })
            .addCase(actionEventRoundParticipantAssignPrize.rejected, (state) => {
                state.loadingEventRoundParticipantAssignPrize = false
            })

            //eventRoundPrizes
            .addCase(actionEventRoundPrizes.pending, (state) => {
                state.loadingEventRoundPrizes = true
            })
            .addCase(actionEventRoundPrizes.fulfilled, (state, action) => {
                state.loadingEventRoundPrizes = false
                state.eventRoundPrizes = action.payload
            })
            .addCase(actionEventRoundPrizes.rejected, (state) => {
                state.loadingEventRoundPrizes = false
            })

            //eventCompletedRound
            .addCase(actionEventCompletedRound.pending, (state) => {
                state.loadingEventCompletedRound = true
            })
            .addCase(actionEventCompletedRound.fulfilled, (state, action) => {
                state.loadingEventCompletedRound = false
                state.eventCompletedRound = action.payload
            })
            .addCase(actionEventCompletedRound.rejected, (state) => {
                state.loadingEventCompletedRound = false
            })

            //event round participant swap
            .addCase(actionEventRoundParticipantSwap.pending, (state) => {
                state.loadingEventRoundParticipantSwap = true
            })
            .addCase(actionEventRoundParticipantSwap.fulfilled, (state, action) => {
                state.loadingEventRoundParticipantSwap = false
                state.eventRoundParticipantSwap = action.payload
            })
            .addCase(actionEventRoundParticipantSwap.rejected, (state) => {
                state.loadingEventRoundParticipantSwap = false
            })

            //event round participant swapped
            .addCase(actionEventRoundParticipantSwapped.pending, (state) => {
                state.loadingEventRoundParticipantSwapped = true
            })
            .addCase(actionEventRoundParticipantSwapped.fulfilled, (state, action) => {
                state.loadingEventRoundParticipantSwapped = false
                state.eventRoundParticipantSwapped = action.payload
            })
            .addCase(actionEventRoundParticipantSwapped.rejected, (state) => {
                state.loadingEventRoundParticipantSwapped = false
            })

            // event round edit
            .addCase(actionEventRoundEdit.pending, (state) => {
                state.loadingEventRoundEdit = true
            })
            .addCase(actionEventRoundEdit.fulfilled, (state, action) => {
                state.loadingEventRoundEdit = false
                state.eventRoundEdit = action.payload
            })
            .addCase(actionEventRoundEdit.rejected, (state) => {
                state.loadingEventRoundEdit = false
            })

            // event round edit details
            .addCase(actionEventRoundEditDetails.pending, (state) => {
                state.loadingEventEditRoundDetails = true
            })
            .addCase(actionEventRoundEditDetails.fulfilled, (state, action) => {
                state.loadingEventEditRoundDetails = false
                state.eventEditRoundDetails = action.payload
            })
            .addCase(actionEventRoundEditDetails.rejected, (state) => {
                state.loadingEventEditRoundDetails = false
            })

            // round participant delete
            .addCase(actionRoundParticipantDelete.pending, (state) => {
                state.loadingRoundParticipantDelete = true
            })
            .addCase(actionRoundParticipantDelete.fulfilled, (state, action) => {
                state.loadingRoundParticipantDelete = false
                state.roundParticipantDelete = action.payload
            })
            .addCase(actionRoundParticipantDelete.rejected, (state) => {
                state.loadingRoundParticipantDelete = false
            })

            // round participant move
            .addCase(actionRoundParticipantMove.pending, (state) => {
                state.loadingRoundParticipantMove = true
            })
            .addCase(actionRoundParticipantMove.fulfilled, (state, action) => {
                state.loadingRoundParticipantMove = false
                state.roundParticipantMove = action.payload
            })
            .addCase(actionRoundParticipantMove.rejected, (state) => {
                state.loadingRoundParticipantMove = false
            })

            // round participant add
            .addCase(actionParticipantMoveToRound.pending, (state) => {
                state.participantToRound = true
            })
            .addCase(actionParticipantMoveToRound.fulfilled, (state, action) => {
                state.loadingRoundParticipantAdd = false
                state.participantToRound = action.payload
            })
            .addCase(actionParticipantMoveToRound.rejected, (state) => {
                state.participantToRound = false
            })

            // update current token
            .addCase(actionUpdateCurrentToken.pending, (state) => {
                state.loadingUpdateCurrentToken = true
            })
            .addCase(actionUpdateCurrentToken.fulfilled, (state, action) => {
                state.loadingUpdateCurrentToken = false
                state.updateCurrentToken = action.payload
            })
            .addCase(actionUpdateCurrentToken.rejected, (state) => {
                state.loadingUpdateCurrentToken = false
            })

            // race time wise participants
            .addCase(actionRaceTimeWiseParticipants.pending, (state) => {
                state.loadingRaceTimeWiseWithParticipants = true
            })
            .addCase(actionRaceTimeWiseParticipants.fulfilled, (state, action) => {
                state.loadingRaceTimeWiseWithParticipants = false
                state.raceTimeWiseWithParticipants = action.payload
            })
            .addCase(actionRaceTimeWiseParticipants.rejected, (state) => {
                state.loadingRaceTimeWiseWithParticipants = false
            })

            //eventParticipantGenerateRound
            .addCase(actionEventParticipantGenerateRound.pending, (state) => {
                state.loadingEventParticipantGenerateRound = true
            })
            .addCase(actionEventParticipantGenerateRound.fulfilled, (state, action) => {
                state.loadingEventParticipantGenerateRound = false
                state.eventParticipantGenerateRound = action.payload
            })
            .addCase(actionEventParticipantGenerateRound.rejected, (state) => {
                state.loadingEventParticipantGenerateRound = false
            })

            //eventParticipant add to Round
            .addCase(actionEventParticipantAddToRound.pending, (state) => {
                state.loadingAddParticipantRoundStatus = true
            })
            .addCase(actionEventParticipantAddToRound.fulfilled, (state, action) => {
                state.loadingAddParticipantRoundStatus = false
                state.eventRoundParticipantAddStatus = action.payload
            })
            .addCase(actionEventParticipantAddToRound.rejected, (state) => {
                state.loadingAddParticipantRoundStatus = false
            })

            //eventParticipant  Round List TabView
            .addCase(actionEventParticipantRoundList.pending, (state) => {
                state.loadingParticipantRoundList = true
            })
            .addCase(actionEventParticipantRoundList.fulfilled, (state, action) => {
                state.loadingParticipantRoundList = false
                state.eventRoundParticipantList = action.payload
            })
            .addCase(actionEventParticipantRoundList.rejected, (state) => {
                state.loadingParticipantRoundList = false
            })
    }
})
export const {
    resetEventRounds,
    resetEventRoundParticipant,
    resetEventRoundsAdd,
    resetEventRoundsParticipantsDetails,
    resetRoundParticipantsScoreAdd,
    resetRoundParticipantsScoreEdit,
    resetEventRoundUpdateStatus,
    resetEventRoundParticipantAssignPrize,
    resetEventRoundPrizes,
    resetEventCompletedRound,
    resetEventRoundParticipantSwap,
    resetEventRoundParticipantSwapped,
    resetEventRoundEdit,
    resetEditEventRoundDetails,
    resetRoundParticipantDelete,
    resetRoundParticipantMove,
    resetParticipantToRound,
    resetUpdateCurrentToken,
    resetRaceTimeWiseParticipants,
    resetEventParticipantGenerateRound,
    resetAddEventParticipantRoundResponse,
    resetEventParticipantRoundListResponse
} = appEventRoundSlice.actions

export default appEventRoundSlice.reducer